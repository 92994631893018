import React, { lazy, Suspense } from 'react'
import LoaderTerpel from 'components/LoaderTerpel/LoaderTerpel'
import { Route, Switch } from 'react-router-dom'
//rutas
const AjusteInicial = lazy(async () => import('./AjusteInicial/AjusteInicial'))
const Entrada = lazy(async () => import('./Entrada'))
const LibroCompras = lazy(async () => import('./LibroCompras'))
const LibroFacturacion = lazy(async () => import('./LibroFacturacion/index'))
const OrdenCompra = lazy(async () => import('./OrdenCompra'))
const Productos = lazy(async () => import('./Productos'))
const ReporteVentas = lazy(async () => import('./ReporteVentas/ReporteVentasCanastilla'))
const ReporteVentasDetallada = lazy(async () => import('./ReporteVentas/ReporteVentasCanastillaDetallada'))
const Salida = lazy(async () => import('./Salida'))
const Promociones = lazy(async () => import('./Promociones'))
const CostoYUtilidad = lazy(async () => import('./CostoYUtilidad'))


const Canastillas = ({ match }: any) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<LoaderTerpel />}>
          <Route path={`${match.url}/ajuste-inicial-canastilla`} component={AjusteInicial} />
          <Route path={`${match.url}/entrada-canastilla`} component={Entrada} />
          <Route path={`${match.url}/libro-compras-canastilla`} component={LibroCompras} />
          <Route path={`${match.url}/libro-facturacion-canastilla`} component={LibroFacturacion} />
          <Route path={`${match.url}/orden-compra-canastilla`} component={OrdenCompra} />
          <Route path={`${match.url}/productos-canastilla`} component={Productos} />
          <Route path={`${match.url}/reporte-ventas-canastilla`} component={ReporteVentas} />
          <Route path={`${match.url}/reporte-ventas-detallada-canastilla`} component={ReporteVentasDetallada} />
          <Route path={`${match.url}/salida-canastilla`} component={Salida} />
          <Route path={`${match.url}/promocion-canastilla`} component={Promociones} />
          <Route path={`${match.url}/costo-utilidad-canastilla`} component={CostoYUtilidad} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Canastillas
