import { createTheme, Tooltip } from '@material-ui/core';
import { Props } from 'interfaces/Tanque/Tanque.interface';
import React, { useEffect, useState } from 'react'
import { formatearFecha, formatosFecha } from 'util/formatDate';
import { Info } from '@material-ui/icons'
import './NewTanque.css'
import { ThemeProvider } from '@material-ui/styles';
// import EscaleraTanque from "assets/images/dashboard/tanques/escalera-tanque.png"
import MedidaTanque from "assets/images/dashboard/tanques/medida-tanque.png"
import BaseTanque from "assets/images/dashboard/tanques/base-tanque.png"
import EscaleraTanque from "assets/images/dashboard/tanques/escalera-tanque.png"
import CabeceraTanque from "assets/images/dashboard/tanques/cabecera-tanque.png"

const NewTanque = ({ data, size, color }: Props) => {
    const [porcentaje, setPorcentaje] = useState('')
    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    color: "black",
                    backgroundColor: "white",
                    borderRadius: '15px',
                    boxShadow: '0 0 6px rgba(0,0,0,0.85)',
                }
            }
        }
    });


    const asignarPorcentaje = () => {
        switch (true) {
            case ((data.contenido / data.capacidad) * 100) >= 0 && ((data.contenido / data.capacidad) * 100) <= 10:
                setPorcentaje('p1')
                break;
            case ((data.contenido / data.capacidad) * 100) > 10 && ((data.contenido / data.capacidad) * 100) <= 20:
                setPorcentaje('p2')
                break;
            case ((data.contenido / data.capacidad) * 100) > 20 && ((data.contenido / data.capacidad) * 100) <= 30:
                setPorcentaje('p3')
                break;
            case ((data.contenido / data.capacidad) * 100) > 30 && ((data.contenido / data.capacidad) * 100) <= 40:
                setPorcentaje('p4')
                break;
            case ((data.contenido / data.capacidad) * 100) > 40 && ((data.contenido / data.capacidad) * 100) <= 50:
                setPorcentaje('p5')
                break;
            case ((data.contenido / data.capacidad) * 100) > 50 && ((data.contenido / data.capacidad) * 100) <= 60:
                setPorcentaje('p6')
                break;
            case ((data.contenido / data.capacidad) * 100) > 60 && ((data.contenido / data.capacidad) * 100) <= 70:
                setPorcentaje('p7')
                break;
            case ((data.contenido / data.capacidad) * 100) > 70 && ((data.contenido / data.capacidad) * 100) <= 80:
                setPorcentaje('p8')
                break;
            case ((data.contenido / data.capacidad) * 100) > 80 && ((data.contenido / data.capacidad) * 100) <= 90:
                setPorcentaje('p9')
                break;
            case ((data.contenido / data.capacidad) * 100) > 90 && ((data.contenido / data.capacidad) * 100) <= 100:
                setPorcentaje('p10')
                break;
            default:
                setPorcentaje('p0')
                break;
        }
    }
    useEffect(() => {
        asignarPorcentaje()
    }, [data])
    return (
        <div style={{ backgroundColor: '#f2f1f6', borderRadius: '10px', padding: '50px 30px'}} >
            <div className={`container__tanque `}>
                <ThemeProvider theme={theme}>
                    <Tooltip
                        title={
                            <div className='container__info'>
                                <h2>{`${data.name.toUpperCase()}`}</h2>
                                <p>Nivel de agua: <span>{`${data.nivelAgua}%`}</span></p>
                                <p>Estado: <span>{`${data.estado === 'A' ? 'ACTIVO' : 'INACTIVO'}`}</span></p>
                                <p>Ultima Lectura: <span>{`${formatearFecha(data.ultimaLectura, formatosFecha.tiempo2)}`}</span></p>
                            </div>
                        }
                        enterDelay={300} placement="right" arrow
                    >
                        <div>
                            <div className='icono'>
                                <Info color='action' />
                            </div>
                            <div className='container__nombre'>
                                <h2>{data.bodega}</h2>
                            </div>
                            <img className='cabecera__tanque' src={CabeceraTanque} alt="Cabecera de Tanque" />
                            <img className='medida__tanque' src={MedidaTanque} alt="Medida de Tanque" />
                            <div className='container__cilindro'>
                                <div className='container__label'>
                                    {`${data.contenido}/${data.capacidad}`}
                                    <p>{`(${(((data.contenido / data.capacidad) * 100).toFixed(2))}%)`}</p>
                                </div>
                                <div className={`liquido activo ${color} ${porcentaje}`}>{/*LIQUIDO DEL TANQUE*/}</div>
                            </div>
                            <img className='escalera__tanque' src={EscaleraTanque} alt="Escalera de Tanque" />
                            <img className='base__tanque' src={BaseTanque} alt="Base de Tanque" />
                            <div className='container__tipo'>
                                <h2>{data.tipo}</h2>
                            </div>
                        </div>
                    </Tooltip>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default NewTanque
