// import Indicadores, { IndicadorBody, IndicadorHeader, ContainerItems, IndicadorFooterItem, IndicadorFooter } from './Indicadores'
import Indicadores, { IndicadorBody, IndicadorHeader, ContainerItems, IndicadorFooterItem, IndicadorFooter } from './NewIndicadores'

const Indicador = Object.assign(Indicadores, {
    Header: IndicadorHeader,
    Body: IndicadorBody,
    Footer: IndicadorFooter,
    Item: IndicadorFooterItem,
    container: ContainerItems
})

export default Indicador