import React, { useEffect, useState } from 'react'
//panel del filtro
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import { setearMiles } from "util/funciones"
import { determinateDate, determinateOperation } from '../ChartHelpers/Chart.helper';
import { getChartsData } from '../ChartHelpers/Charts.services';
import { IDatasetChart } from '../ChartHelpers/Charts.interface';
import { FiltrosOperation, FiltrosFecha } from './Components';
window.Highcharts = Highcharts;
HCSoldGauge(Highcharts);
const ChartTopPromotores = ({ updateData, identificadorEstacion }: any): React.ReactElement => {
    const [selectionDate, setSelectionDate] = useState(0)
    const [selectionOperation, setSelectionOperation] = useState(3)

    useEffect(() => {
        getData()
    }, [updateData, selectionDate,selectionOperation,identificadorEstacion])

    const getData = async (): Promise<void> => {
        const { fechaInicial, fechaFinal } = determinateDate(selectionDate)
        const { operacion, estadoMovimiento } = determinateOperation(selectionOperation)
        const body = {
            fecha_inicial: fechaInicial,
            fecha_final: fechaFinal,
            identificadorEstacion: identificadorEstacion,
            tipoMovimiento: operacion,
            tipoEstadoMovimiento: estadoMovimiento
        };
        const data = await getChartsData('PROMOTORES_MAS_VENTAS_HOY', body)
        construirDatos(data)
    }

    const construirDatos = (datos: any[]) => {
        const data: IDatasetChart = {
            categories: [],
            series: []
        }
        try {
            if (datos != null) {
                datos.forEach((promotor) => {
                    data.categories.push(`${promotor.promotor} (${promotor.cantidad} Ventas)`)
                    data.series.push(parseFloat(promotor.total))
                })
            }
        } catch (error) { }
        construirGrafico(data.categories, data.series)
    }



    const construirGrafico = (categories: string[], series: any[]): void => {
        Highcharts.chart('ventas-top-promotor', {

            chart: {
                type: "bar",
            },
            title: {
                text: "",
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return '<b>' + this.point.category + '</b>:  $' + setearMiles(this.point.y);
                }
            },
            xAxis: {
                categories: categories,
            },
            yAxis: {
                min: 0,
                title: {
                    text: "TOTAL VENTAS",
                },
            },
            legend: {
                reversed: true,
            },
            plotOptions: {
                series: {
                    stacking: "normal",
                },
            },
            series: [{
                name: "ventas",
                data: series
            }],
        } as any);
    }


    return (
        <>
            <HeaderTitle title={'PROMOTORES CON MAS VENTAS'} />
            <FiltrosFecha onChange={(selection: number) => setSelectionDate(selection)} />
            <FiltrosOperation onChange={(selection: number) => setSelectionOperation(selection)} prevActive={selectionOperation} />
            <div
                id="ventas-top-promotor"
                className="col-md-11 mx-auto"
            /*style={
                isShow
                    ? { marginTop: "15px", display: "block" }
                    : { display: "none" }
            }*/
            ></div>
        </>
    )
}
export default ChartTopPromotores
