// @vendors
import React, { useEffect } from 'react'
import Highcharts from 'highcharts'

// @styles
import './ChartVentasDiarias.css'

// @utils
import { setearMiles } from 'util/utils'
import { formatearFecha } from 'util/formatDate'
import moment from 'moment'
interface Props {
    data: any[]
    type: 'line' | 'area'
    color?: string
    length: 1 | 2 | 3
    updateData: number
}

// const startDate = moment().subtract(1, 'months');
const ChartVentasDiarias = ({ data, type, color, length, updateData }: Props) => {
    const startDate = moment().subtract(1, 'months').startOf('day'); // Fecha de inicio: 1 mes atrás desde hoy
    const endDate = moment().endOf('day'); // Fin del rango: el final del día actual

    const crearGrafica = () => {
        const options = {
            chart: {
                type: type,
                series: {
                    color: color
                }
            },
            title: {
                text: ''
            },
            yAxis: {
                title: {
                    text: ''
                },
                /*  labels: {
                     format: '${value}',
                 }, */

                labels: {
                    formatter: (label: any) => '$' + setearMiles(label.value ?? 0),

                    tooltip: {
                        formatter(this: Highcharts.TooltipFormatterContextObject) {
                            return ' % ' + setearMiles(this.point.y ?? 0)
                        }
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                title: {
                    style: { color: 'black', fontSize: '1.2rem' }
                },
                tickInterval: 86400000, // Dos día en milisegundos
                max: endDate.valueOf()
            },
            plotOptions: {
                series: {
                    pointInterval: 86400000, //One Day
                    pointStart: startDate.add(1, 'days').valueOf(), // Convierte la fecha de inicio a milisegundos
                    marker: {
                        enabled: false
                    }
                }
            },
            series: data.map(item => ({
                name: item.name,
                color: color,
                data: item.data
            })),
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return `  $ ${setearMiles(this.point.y ?? 0)}  <br/>  ${formatearFecha(new Date(this.point.x), 'DD/MM/YYYY')}`
                }
            }
        }
        Highcharts.chart('container__chartVentasDiarias', (options as any) as Highcharts.Options)
    }

    useEffect(() => {
        crearGrafica()
    }, [updateData, data])

    return (
        <>
            <div id="container__chartVentasDiarias" className="col-md-12 mx-auto"></div>
            {/* <FiltrosOperation onChange={(selection: number) => setSelectionOperation(selection)} prevActive={selectionOperation} /> */}
        </>
    )
}

export default ChartVentasDiarias

