import { config } from 'dotenv'

config()
const server = ''

let puertoCentury = process.env.REACT_APP_CENTURY
let puertoApp = process.env.REACT_APP_APP
let puertoMovimiento = process.env.REACT_APP_MOVIMIENTO
let puertoEquipo = process.env.REACT_APP_EQUIPO
let puertoMultimedia = process.env.REACT_APP_MULTIMEDIA
let puertoLazoLite = process.env.REACT_APP_LAZOLITE
let puertoSap = process.env.REACT_APP_SAP
let captchapKey = process.env.REACT_APP_CAPTCHA_KEY
let puertoAnalyzer = 9002
let puertoControlMaster = process.env.REACT_APP_CONTROL_MASTER
const puertpAppRumboWS = process.env.REACT_APP_RUMBO_WS
let host = process.env.REACT_APP_HOST
let host2 = process.env.REACT_APP_HOST2
let HostPDF = process.env.REACT_APP_HOST
let hostMultimedia = process.env.REACT_APP_HOST_MULTIMEDIA
let puerto_integracion_sap = process.env.REACT_APP_INTEGRACION_SAP
const hostMqtt = process.env.REACT_APP_HOST_MQTT
const mqttUsername = process.env.REACT_APP_USER_MQTT
const mqttPassword = process.env.REACT_APP_PASS_MQTT
const mqttPort = process.env.REACT_APP_MQTT
const apiKeyMap = process.env.REACT_APP_MAPS_API_KEY
const puerto_reporte_service = process.env.REACT_APP_REPORTE_SERVICE
const url_socket = process.env.REACT_APP_URL_SOCKET
const puertoMSfe = process.env.REACT_APP_MS_FE || 7012
const puertoIEGenexis = process.env.REACT_APP_IE_GENEXIS || 6969
const puertoClientMS = process.env.REACT_APP_CLIENT_MS || 8716
const puertoUpgrateInvoice = process.env.REACT_APP_UPGRATE_INVOICE_PORT || 10010
const responseOK = 200
const responseSucces = 201
const responseSesionCaducada = 205
const responsePartialContent = 206
const responseError = 400
const responseErrorInternal = 500
const responseErrorServidor = 401
const versionApp = "1.0.0.0"
const versionCode = "1.0.0.0"
const aplicacion = process.env.REACT_APP_APPLICATION
const ntoken = 'Bearer '.concat(sessionStorage.getItem('token'))
const server_key = process.env.REACT_APP_KEY

function obtenerToken() {
    return 'Bearer '.concat(sessionStorage.getItem('token'))
}

export default {
    //hosts
    host,
    hostMultimedia,
    HostPDF,
    //ports
    puertoApp,
    captchapKey,
    puertoCentury,
    puertoMovimiento,
    puertpAppRumboWS,
    puertoEquipo,
    puertoMultimedia,
    puertoLazoLite,
    puertoAnalyzer,
    puertoSap,
    puerto_integracion_sap,
    puerto_reporte_service,
    puertoControlMaster,
    puertoMSfe,
    puertoClientMS,
    puertoIEGenexis,
    puertoUpgrateInvoice,
    //keys
    server_key,
    ntoken,
    versionApp,
    versionCode,
    aplicacion,
    //response
    responseOK,
    responseSucces,
    responsePartialContent,
    responseError,
    responseSesionCaducada,
    responseErrorInternal,
    responseErrorServidor,
    //mqtt
    hostMqtt,
    mqttUsername,
    mqttPassword,
    mqttPort,
    obtenerToken,
    apiKeyMap,
    url_socket
}