import {validarLongitudVacia } from "util/funciones";

export const VALIDATIONS_FORM_REGIONAL: any = {
    nombre_regional: [
        {
            required: true,
            message: "Por favor, ingrese el nombre de la regional",
        },
        {
            validator: validarLongitudVacia
        }
    ],
    codigo_regional: [
        {
            required: true,
            message: "Por favor, ingrese codigo de regional",
        },
        {
            validator: validarLongitudVacia
        }
    ],
    id_pais: [
        {
            required: true,
            message: "Por favor, seleccione un país",
        }
    ],
   
}