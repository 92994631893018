import React from 'react'
import { Paper, Grid, InputLabel, Button } from '@material-ui/core'
import { Table, Typography } from 'antd'
import './NovedadesConciliacion.css'
import { sortFuntion } from '../Conciliacion.utils'

interface IProps {
    novedadesList: INovedadConciliador[]
    loading?: boolean
}

export interface INovedadConciliador {
    descripcion: string
}

const NovedadesConciliacion = ({ novedadesList, loading = false }: IProps) => {
    return (
            <Paper elevation={3} style={{ padding: 10, maxHeight: '260px', minHeight: '250px', width:'100%' }} >
                <h2 className={`title-conciliacion-${novedadesList.length > 0 ? 'green': 'red'}`}>{novedadesList.length > 0 ? 'Se requiere gestionar estas novedades para conciliar el día' : 'No hay novedades pendientes'}</h2>
                <Table
                    
                    loading={loading}
                    style={{ minHeight: '250px', maxHeight: '260px' }}
                    pagination={novedadesList.length > 3 ? { pageSize: 3, style: { minHeight: '200px' } } : false}
                    dataSource={sortFuntion(novedadesList)}
                    showHeader={false} columns={[{
                        title: 'descripcion',
                        dataIndex: 'descripcion'
                    }]} />
            </Paper>
    )
}

export default NovedadesConciliacion