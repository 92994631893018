
const checkEmail = (rule: any, value: any, callback: any) => {
  const regexCorreo = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
  if (value && !regexCorreo.test(value)) {
    return Promise.reject('Ingrese una direccion de correo valida')
  } else {
    return Promise.resolve()
  }
}

export const RULES_MODAL_PROFILE = {
  nombre: [
    {
      required: true,
      message: "El (los) nombre(s) son obligatorios",
    },
    {
      pattern: /^[a-zA-Z ]+$/,
      message: 'Ingrese solo texto',
    },
  ],
  apellido: [
    {
      required: true,
      message: "El (los) apellido(s) son obligatorios",
    },
    {
      pattern: /^[a-zA-Z ]+$/,
      message: 'Ingrese solo texto',
    },
  ],
  direccion: [
    {
      min: 3,
      message: "Este campo solo acepta minimo 3 caracteres",
    }
  ],
  telefono: [
    {
      required: true,
      min: 7,
      message: "Debe de ingresar al menos 7 digitos (si es fijo)",
    },
    {
      max: 10,
      message: "Ingresar maximo 10 digitos",
    },
    {
      pattern: /^[0-9]+$/,
      message: 'Ingrese solo valores numéricos',
    },
  ],
  correo: [
    {
      required: true,
      message: "Este campo es obligatorio",
    },
    {
      validator: checkEmail,
    }
  ]
}

export const RULES_PASSWORD_EDIT = {
  currentPassword: [
    {
      required: true,
      message: "Este campo es obligatorio",
    }
  ],
  newPassword: [
    {
      required: true,
      message: "Este campo es obligatorio",
    }
  ],
  verifyPassword: [
    {
      required: true,
      message: "Este campo es obligatorio",
    }
  ]


}
