import React from 'react'
import LOGO from '../../assets/images/logo-app.gif'
import './index.css'


const PreLoaderTerpel = () => {
  return (
    <div style={{ width: '100%', backgroundColor: '#1b1b1b', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <img src={LOGO} style={{ alignSelf: 'center', maxWidth: '300px' }} />
      {/* <div className="spinner-container">
        <div className="spinner large clockwise"></div>
        <div className="spinner medium counterclockwise"></div>
        <div className="spinner small clockwise"></div>
      </div> */}

    </div>
  )
}
export default PreLoaderTerpel
