import axios from "axios";
import server from 'util/Servidor'

export const createRegionalService = async (body:any) =>{
    return axios.post(server.host+server.puerto_integracion_sap + '/api/v1/regional/', body,{ headers: { Authorization: server.obtenerToken() } })
}

export const updateRegionalService = async (body:any, id:number) =>{
    return axios.put(server.host+server.puerto_integracion_sap + `/api/v1/regional/${id}`, body,{ headers: { Authorization: server.obtenerToken() } })
}
export const getRegionalByIdService = async (id:number) =>{
    return axios.get(server.host+server.puerto_integracion_sap + `/api/v1/regional/${id}`,{ headers: { Authorization: server.obtenerToken() } })
}
export const createRegionalGroup = async (body:any) =>{
    return axios.post(server.host+server.puerto_integracion_sap + '/api/v1/regionalBusiness/', body,{ headers: { Authorization: server.obtenerToken() } })
}

