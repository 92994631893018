// TIPO MOVIMIENTO //
export const MOVIMIENTO_INVENTARIO_INICIAL = "001"
export const MOVIMIENTO_ORDEN_COMPRA = "002"
export const MOVIMIENTO_ENTRADA = "003"
export const MOVIMIENTO_ORDEN_PRODUCCION = "004"
export const MOVIMIENTO_SALIDA_DIRECTA = "005"
export const MOVIMIENTO_SALIDA_AVERIA = "006"
export const MOVIMIENTO_INVENTARIO_PERIODICO = "007"
export const MOVIMIENTO_TRASLADO = "008"
export const MOVIMIENTO_VENTAS_COMPLEMENTARIOS = "009"
export const MOVIMIENTO_REMISION = "010"
export const MOVIMIENTO_SALIDA_MERMA = "011"
export const MOVIMIENTO_ANULACION_VENTAS = "012"
export const MOVIMIENTO_ALIVIO_CAJA = "013" //SOBRES
export const MOVIMIENTO_CALIBRACIONES = "014"
export const MOVIMIENTO_REMARCACION = "015"
export const MOVIMIENTO_CONSUMO_PROPIO = "016"
export const MOVIMIENTO_VENTAS_COMBUSTIBLES = "017"
export const MOVIMIENTO_VENTAS_MIXTAS = "018"
export const MOVIMIENTO_PROGRAMACION_SERVICIOS = "019"



// MOVIMIENTOS ESTADOS //
export const MOVIMIENTO_ESTADO_INVENTARIO_INICIAL_WEB = "001001"
export const MOVIMIENTO_ESTADO_INVENTARIO_INICIAL_CANASTILLA = "001003"
export const MOVIMIENTO_ESTADO_INVENTARIO_INICIAL_KIOSCO = "001004"
export const MOVIMIENTO_ESTADO_INVENTARIO_INICIAL_POS = "001002"
export const MOVIMIENTO_ESTADO_ORDEN_COMPRA_SIN_ENTRADA_WEB = "002001"
export const MOVIMIENTO_ESTADO_ORDEN_COMPRA_SIN_ENTRADA_POS = "002002"
export const MOVIMIENTO_ESTADO_ORDEN_COMPRA_PARCIAL = "002003"
export const MOVIMIENTO_ESTADO_ORDEN_COMPRA_ANULADA = "002006"
export const MOVIMIENTO_ESTADO_ORDEN_COMPRA_COMPLETA = "002004"
export const MOVIMIENTO_ESTADO_ENTRADA_DIRECTA_WEB = "003001"
export const MOVIMIENTO_ESTADO_ENTRADA_DIRECTA_POS = "003002"
export const MOVIMIENTO_ESTADO_ENTRADA_ORDEN_COMPRA_WEB = "003003"
export const MOVIMIENTO_ESTADO_ENTRADA_ORDEN_COMPRA_POS = "003004"
export const MOVIMIENTO_ESTADO_SALIDA_DIRECTA_WEB = "005001"
export const MOVIMIENTO_ESTADO_SALIDA_DIRECTA_POS = "005002"
export const MOVIMIENTO_ESTADO_SALIDA_AVERIA_WEB = "006001"
export const MOVIMIENTO_ESTADO_SALIDA_AVERIA_POS = "006002"
export const MOVIMIENTO_ESTADO_TRASLADO_WEB = "008001"
export const MOVIMIENTO_ESTADO_TRASLADO_POS = "008002"
export const MOVIMIENTO_ESTADO_VENTA_CANASTILLA = "009001"
export const MOVIMIENTO_ESTADO_VENTA_CANASTILLA_ANULADA = "009002"
export const MOVIMIENTO_ESTADO_SALIDA_MERMA_WEB = "011001"
export const MOVIMIENTO_ESTADO_SALIDA_MERMA_POS = "011002"
export const MOVIMIENTO_ESTADO_REMARCACION_WEB = "015001"
export const MOVIMIENTO_ESTADO_REMARCACION_POS = "015002"
export const MOVIMIENTO_ESTADO_CONSUMO_PROPIO = "016001"
export const MOVIMIENTO_ESTADO_VENTA_COMBUSTIBLE = "017001"
export const MOVIMIENTO_ESTADO_VENTA_MISXTA = "018001"
export const MOVIMIENTO_ESTADO_SERVICIO_PENDIENTE = "019001"
export const MOVIMIENTO_ESTADO_SERVICIO_CANCELADO = "019002"
export const MOVIMIENTO_ESTADO_SERVICIO_APLAZADO = "019003"
export const MOVIMIENTO_ESTADO_SERVICIO_TERMINADO = "019004"
export const MOVIMIENTO_ESTADO_NOTA_A_CREDITO_NORMAL = "032001"
export const MOVIMIENTO_ESTADO_NOTA_A_CREDITO_ANULADA = "032002"



// MOVIMIENTOS FUELLY //
export const SOLICITUD_CREDITO = "020"
export const CONVERSION_GNV = "021"
export const RECAUDO = "023"
export const RECEPCION_RECIBO = "024"
export const RECEPCION_CONTENEDOR = "025"
//clientes+
export const DESCUENTOS_CUPO = "026"
export const RECARGAS_CUPO = "027"
export const CONCILIACION_EDS_FINANCIERA = "028"
export const ABONO_RECAUDO = "029"
export const AJUSTE_SALDO_CUPO = "036"


//ANALYZER
export const VENTA_ANALYZER = "030"
export const FACTURACION_ELECTRONICA = "031"
export const CONTINGENCIA = "018"
export const NOTA_A_CREDITO = "032"
export const VENTA_MIXTA = "033"
export const VENTA_KIOSCO = "035"


// CONFIGURACION VENTAS //
export const DEVOLUCION_DE_LOS_BIENES = "001"
export const ANULACION_FACTURA_ELECTRONICA = "002"
export const REBAJA_DESCUENTO_TOTAL = "003"
export const AJUSTE_PRECIO = "004"
export const OTROS = "005"


// ESTADOS FACTURACION ELECTRONICA //
export const ESTADOS_FACTURACION_ELECTRONICA = {
    AUTORIZADA: 2,
    NO_AUTORIZADA: 3,
    ERROR_ANTES_DE_ENVIAR: 4,
    ESPERA_AUTORIZACION: 5,
    DEVUELTA_RECEPCION: 6,
    ESPERA_RESPUESTA: 7,
    DEVUELTO_EDOC: 15,
    DOCUMENTO_NO_ECONTRADO: 18
}

// MOVIMIENTO ESTADO FUELLY //
export const MOVIMIENTO_ESTADO_CREDITO_CREADO = "020001"
export const MOVIMIENTO_ESTADO_CREDITO_APROBADO = "020002"
export const MOVIMIENTO_ESTADO_CREDITO_RECHAZADO = "020003"
export const MOVIMIENTO_ESTADO_CONVERSION_DIAGNOSTICO = "021001"
export const MOVIMIENTO_ESTADO_CONVERSION_INSTALACION_KIT_ECOGNV = "021002"
export const MOVIMIENTO_ESTADO_CONVERSION_INSTALACION_CERTIFICADA = "021003"
export const MOVIMIENTO_ESTADO_CONVERSION_INSTALACION_APROBADA = "021004"
export const MOVIMIENTO_ESTADO_CONVERSION_INSTALACION_RECHAZADA = "021005"
export const MOVIMIENTO_ESTADO_CONVERSION_INSTALACION_TEMPORAL = "021006"
export const MOVIMIENTO_ESTADO_CONVERSION_INSTALACION_SUSPENDIDO = "021007"
export const ESTADO_MOVIMEINTO_CONCILIACION_EDS_FINANCIERA_CREADA = "028001"
export const ESTADO_MOVIMEINTO_CONCILIACION_EDS_FINANCIERA_RECHAZADA = "028002"
export const ESTADO_MOVIMEINTO_CONCILIACION_EDS_FINANCIERA_APROBADA = "028003"

//VENTA EN MEXICO
//export const VENTA_GNV = "021"
//export const MOVIMIENTO_ESTADO_VENTA_CON_RECAUDO = "021001"

//

