import React, { useEffect, useState } from 'react'
import './ContainerDashBoard.css'
import { DragIndicator } from '@material-ui/icons'
import { Tooltip } from '@material-ui/core'

interface Props {
    children: React.ReactElement[] | React.ReactElement
    width: 1 | 2 | 3
    titulo: string
    id: number
    noData?: boolean
}

const ContainerDashBoard = ({ children, width, titulo, id, noData }: Props) => {
    const [area, setArea] = useState({
        lg: 0,
        md: 0,
        sm: 0,
        xs: 0
    })
    const [expanded] = useState(false)
    const [close] = useState(false)

    const calcularArea = () => {
        switch (width) {
            case 3:
                setArea({
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 11
                })
                break
            case 2:
                setArea({
                    lg: 8,
                    md: 8,
                    sm: 8,
                    xs: 11
                })
                break
            case 1:
                setArea({
                    lg: 4,
                    md: 4,
                    sm: 4,
                    xs: 11
                })
                break

            default:
                break
        }
    }
    useEffect(() => {
        calcularArea()
    }, [width])

    return (
        <>
            <div
                id="box"
                style={{ display: `${close || noData ? 'none' : ''}` }}
                className={`container__box box-lg-${area.lg} box-md-${area.md} box-sm-${area.sm} box-xs-${area.xs} box_hover minimize`}

            >
                <div className="container__contenido">{children}</div>
                <div className="menu">
                    <div className="titulo">
                        <h2>{titulo.toUpperCase()}</h2>
                    </div>
                    <div className="opciones">
                        {!expanded && (
                            <Tooltip title={'MOVER'} enterDelay={200}>
                                <div className="opcion handle">
                                    <DragIndicator />
                                </div>
                            </Tooltip>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContainerDashBoard

