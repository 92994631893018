import React, { createContext, useLayoutEffect, useState } from "react";
import Storage from 'util/store/Storage'

export const EmpresasContext = createContext({} as any)
export const EmpresasSelectProvider = ({ children }: any) => {
    const { empresasHijos } = Storage.getState()
    const [empresa, setEmpresa] = useState(empresasHijos);

    useLayoutEffect(() => {
        setEmpresa(empresasHijos)
    }, []);

    return (
        <EmpresasContext.Provider value={{
            empresa, setEmpresa,
        }}>
            {children}
        </EmpresasContext.Provider>
    )

}