// @vendors
import React, { useEffect, useState } from 'react'
import Highcharts, { SeriesOptionsType } from 'highcharts'
import { CircularProgress } from '@material-ui/core'
import moment from 'moment'
import 'moment/locale/es'

// @services
import {
    ventasCombustibleDiarias,
    ventasCombustibleMensuales,
    ventasCombustibleSemanales
} from '../../app/vistas/Dashboard/servicios'
import { getSelectTiposProductosCombustibles } from 'app/vistas/Reportes/servicios/serviciosSelect'

// @charts
import { FiltrosFecha } from 'app/vistas/Dashboard/Charts/Components'

// @storage
import Storage from 'util/store/Storage'



// @utils


interface VentasCombustible {
    cantidad: number
    descripcion: string
    tipo_producto_id: number
}

//REPORTE DE GALONAJE POR DIA (HOY Y ESTA SEMANA)
interface ReporteDiaCombustible {
    fecha: string
    ventas: VentasCombustible[]
}

//REPORTE DE GALONAJE POR UN RANGO DE FECHA (ESTE MES)
interface ReporteRangoCombustible {
    fecha_inicial: string
    fecha_final: string
    ventas: VentasCombustible[]
}

//REPORTE GALONAJE POR MES (ESTE AÑO)
interface ReporteMesCombsutible {
    mes: number
    ventas: VentasCombustible[]
}

enum TIPO_REPORTE {
    HOY = 0,
    SEMANA = 1,
    MES = 2,
    AÑO = 3
}

const colores: any = {
    CORRIENTE: 'red',
    EXTRA: 'blue',
    DIESEL: 'yellow',
    GLP: 'gray',
    GNV: 'green'
}

const CharVentasCombustibles = ({ updateData, identificadorEstacion }: any) => {
    const [cargandoCombustibles, setCargandoCombustibles] = useState(true)
    const [cargandoReportes, setCargandoReportes] = useState(true)
    const [tipoReporte, setTipoReporte] = useState<TIPO_REPORTE>(TIPO_REPORTE.HOY)
    const [reportes, setReportes] = useState<
        ReporteDiaCombustible[] | ReporteRangoCombustible[] | ReporteMesCombsutible[]
    >([])
    const [combustibles, setCombustibles] = useState<any[]>([])
    const { empresasHijos } = Storage.getState()
    const [señalPeticion, setSeñalPeticion] = useState<AbortController | null>(null)

    const formatearDatos = (
        reportes: ReporteDiaCombustible[] | ReporteRangoCombustible[] | ReporteMesCombsutible[]
    ): SeriesOptionsType[] => {
        return combustibles?.map(
            combustible =>
                ({
                    name: combustible.label,
                    color: colores[combustible.label as string],
                    data: reportes.map(reporte => {
                        const venta = reporte.ventas?.find(venta => venta.tipo_producto_id === combustible.value)
                        return venta ? venta.cantidad : 0
                    })
                } as SeriesOptionsType)
        )
    }

    const cargarReportes = async () => {
        try {
            const fechaActual = moment()
            let res = null
            /*
        "señalPeticionActual" Permite identificar la peticion que se esta realizando actualmente.
        Al cambiar de tipo de reporte, se cancelará la petición en curso.*/
            const señalPeticionActual = new AbortController()
            setSeñalPeticion(señalPeticionActual)
            switch (tipoReporte) {
                case TIPO_REPORTE.HOY:
                    res = await ventasCombustibleDiarias(
                        identificadorEstacion,
                        fechaActual.startOf('day').format(),
                        fechaActual.endOf('day').format(),
                        señalPeticionActual
                    )
                    break
                case TIPO_REPORTE.SEMANA:
                    res = await ventasCombustibleDiarias(
                        identificadorEstacion,
                        fechaActual.startOf('week').format(),
                        fechaActual.endOf('week').format(),
                        señalPeticionActual
                    )
                    break
                case TIPO_REPORTE.MES:
                    res = await ventasCombustibleSemanales(
                        identificadorEstacion,
                        fechaActual.startOf('month').format(),
                        fechaActual.endOf('month').format(),
                        señalPeticionActual
                    )
                    break
                case TIPO_REPORTE.AÑO:
                    setCargandoReportes(true)
                    res = await ventasCombustibleMensuales(
                        identificadorEstacion,
                        fechaActual.year(),
                        señalPeticionActual
                    )
                    break
            }
            if (res) setReportes(res.data.data as ReporteDiaCombustible[])
        } catch (error) {
            console.error(error)
        } finally {
            setCargandoReportes(false)
        }
    }

    const graficoLabels = () => {
        switch (tipoReporte) {
            case TIPO_REPORTE.HOY:
                return ['Hoy']
            case TIPO_REPORTE.SEMANA:
                return (reportes as ReporteDiaCombustible[]).map(reporte =>
                    moment(reporte.fecha)
                        .locale('es')
                        .format('ddd')
                )
            case TIPO_REPORTE.MES:
                return (reportes as ReporteRangoCombustible[]).map(reporte => {
                    const fechaIncial = moment(reporte.fecha_inicial)
                        .locale('es')
                        .format('D')
                    const fechaFinal = moment(reporte.fecha_final)
                        .locale('es')
                        .format('D')
                    return `${fechaIncial} - ${fechaFinal}`
                })
            case TIPO_REPORTE.AÑO:
                return (reportes as ReporteMesCombsutible[]).map(reporte => moment(reporte.mes, 'M').format('MMM'))
        }
    }

    const crearGrafica = () => {
        Highcharts.chart('container__chartVentasCombustibles', {
            chart: { type: 'column', animation: false },
            title: { text: '' },
            xAxis: { categories: graficoLabels(), crosshair: true },
            yAxis: { min: 0, title: { text: 'Galones (G)' } },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: `<tr>
                                <td style="color:{pickColor(series.color)};padding:0">{series.name}:</td>
                                <td style="padding:0"><b>{point.y:.1f}GL</b></td>
                            </tr>`,
                footerFormat: '</table>',
                shared: true,
                useHTML: true,
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: formatearDatos(reportes)
        })
    }

    const cargarCombustibles = async () => {
        setCargandoCombustibles(true)
        setCombustibles(await getSelectTiposProductosCombustibles(empresasHijos))
        setCargandoCombustibles(false)
    }

    useEffect(() => {
        cargarCombustibles()
    }, [])

    useEffect(() => {

             cargarReportes()

    }, [updateData])

    useEffect(() => {
        if (señalPeticion) señalPeticion.abort()
        cargarReportes()
    }, [tipoReporte, identificadorEstacion])

    useEffect(() => {
        if (!cargandoReportes && !cargandoCombustibles) crearGrafica()
    }, [cargandoReportes, cargandoCombustibles, reportes])

    return (
        <div className="d-flex flex-column w-100 h-100">
            {cargandoReportes || cargandoCombustibles ? (
                <CircularProgress className="m-auto" />
            ) : (
                <div className="w-100 h-100" id="container__chartVentasCombustibles"></div>
            )}
            <div className="mt-auto">
                <FiltrosFecha onChange={(tipo: TIPO_REPORTE) => setTipoReporte(tipo)} />
            </div>
        </div>
    )
}

export default CharVentasCombustibles

