import React, { useState, useLayoutEffect, useMemo } from 'react'
import { LastModulesVisitedContext } from 'context/lastModulesVisitedContext';
import { useSessionStorage } from 'hooks/useSessionStorage'

interface Props {
    children: React.ReactNode
}

const LastModulesVisitedProvider = ({ children }: Props) => {

    const [modules, setModules] = useState([] as { name: string, path: string }[])
    const { getSessionStorage, setSessionStorage } = useSessionStorage()

    const setLastModules = (name: string, path: string): void => {

        const modulesSaved = getSessionStorage('modules');

        if (modulesSaved !== 'N') {
            const isModules = modulesSaved.find((modulo: any) => modulo.name === name);
            if (isModules === undefined) {
                if (modules.length === 3) {
                    modules.pop();
                    setModules([{ name, path }, ...modules]);
                } else {
                    setModules([{ name, path }, ...modules])
                }
            }
        } else setModules([{ name, path }]);

        setSessionStorage('modules', modules);
    }

    const getModulesOfLocalStogare = () => {
        const mods = sessionStorage.getItem('modules');
        if (mods) setModules(JSON.parse(mods));
    }

    useLayoutEffect(() => {
        getModulesOfLocalStogare()
    }, [])


    const value = useMemo(() => {
      return {
        setLastModules,
        modules,
      };
    }, [modules]);

    return (
        <LastModulesVisitedContext.Provider
            value={value}

        >
            {children}
        </LastModulesVisitedContext.Provider >


    )
}

export default LastModulesVisitedProvider
