import { Paper, Grid, InputLabel } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Input, Select, Button, App } from 'antd'
import Storage from 'util/store/Storage'
import { IEstacionInfo, IOptionInfoEDS, IPropsFormConciliador, createDateInstance } from '../Conciliacion.utils'
import CalendarCustom from 'components/CalendarCustom/CalendarCustom'
import { getRequest } from 'app/vistas/Usuarios/Clientes/FormClientes/services'
import { getEmpresasByNegocio, obtenerFechasConNovedades } from '../services/conciliacionPDV.services'
import './Formulario.css'
import { sessionCaducada } from 'util/funciones'

const FormularioConciliadorPDV = ({ form, handlerForm, setForm, onSumbit, loading }: IPropsFormConciliador) => {
    const {notification} = App.useApp()
    const { usuario, negocio } = Storage.getState()
    const [datesConsolidadas, setDatesConsolidadas] = useState([


    ])
    const [datesPendientes, setDatesPendientes] = useState([
    ])
    const [loaders, setLoaders] = useState({
        loadingDates: false
    })
    const [minDateAvailable, setMinDateAvailable] = useState(new Date())


    const [estacionesList, setEstacionesList] = useState<IOptionInfoEDS[]>([])

    const getDateCalendar = async () => {
        try {
            setLoaders((prev) => ({ ...prev, loadingDates: true }))
            if (form.estacion) {
                const response = await obtenerFechasConNovedades(form.estacion)
                setDatesPendientes(response.data.result.newsDateList)
                setMinDateAvailable(createDateInstance(response?.data?.result?.newsStartDate))
            }
        } catch (error:any) {
            console.log('error', error)
            if(error.response?.status == 401){
                sessionCaducada()
            }else{
                notification.error({ message: 'Ocurrió un error al obtener los datos del calendario' })
            }
        } finally {
            setLoaders((prev) => ({ ...prev, loadingDates: false }))
        }
    }

    const isAdmin = () => usuario.tipo === 5


    const getPDVs = async () => {
        const responsePDV = await getEmpresasByNegocio(negocio)
        const pdvResultado: IEstacionInfo[] = responsePDV.data
        if (pdvResultado != null || pdvResultado != undefined) {
            setEstacionesList(pdvResultado?.map(pdv => ({ ...pdv, value: Number(pdv.id), label: pdv.alias })))
        }
    }



    useEffect(() => {
        const estacion = estacionesList.find(estacion => Number(estacion.id) == form?.estacion)
        if (estacion) {
            setForm({
                ...form,
                ciudad: estacion.ciudad || 'No Disponible',
                departamento: estacion.departamento || 'No Disponible',
            })
        }
        getDateCalendar()
    }, [form.estacion])


    useEffect(() => {
        if (usuario.tipo === 5) {
            const { empresa: { atributos } } = usuario

            setEstacionesList([{
                value: Number(usuario.empresas_id),
                label: usuario.empresa.alias,
                alias: usuario.empresa.alias,
                ciudad: atributos.ciudad.label || 'No Disponible',
                departamento: atributos.provincia.label || 'No Disponible',
                pais: atributos.pais.label || 'No Disponible',
                id: usuario.empresas_id

            }])
            setForm({
                ...form,
                ciudad: atributos.ciudad.label || 'No Disponible',
                departamento: atributos.provincia.label || 'No Disponible',
                estacion: Number(usuario.empresas_id)
            })

        } else {
            getPDVs()
        }
    }, [])

    return (
        <Paper elevation={3} style={{ padding: 50 }} >
            <Grid container spacing={5}>
                <Grid item xs={12} md={4} lg={4}>

                    <InputLabel id="demo-simple-select-label" >Estación</InputLabel>
                    <Select
                        showSearch
                        disabled={isAdmin()}
                        filterOption={(input, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                        options={estacionesList}
                        value={form.estacion}
                        onChange={(e) => handlerForm('estacion', e)}
                        id="demo-simple-select-label"
                        style={{ width: '100%' }}
                        size='large'
                    />


                </Grid>
                <Grid item xs={12} md={4} lg={4} >
                    <>
                        <InputLabel id="demo-simple-select-label" >Seleccionar fecha</InputLabel>
                        <CalendarCustom
                            spinning={loaders.loadingDates}
                            onChange={(e) => {
                                handlerForm('fecha', e)
                            }}
                            value={form.fecha}
                            dateSucess={datesConsolidadas}
                            dateDanger={datesPendientes}
                            minDate={minDateAvailable}
                            />
                    </>

                </Grid>
                <Grid item xs={12} md={4} lg={4} style={{display:'flex', width:'100%', justifyContent:'space-around'}} >

                        <div className="legend">
                            <div className="circle" style={{ backgroundColor: 'white' }}></div>
                            <p className="description">{'Días habilitados'}</p>
                        </div>
                        <div className="legend">
                            <div className="circle" style={{ backgroundColor: '#f0f0f0' }}></div>
                            <p className="description">{'Días no habilitados'}</p>
                        </div>
                        <div className="legend">
                            <div className="circle" style={{ backgroundColor: '#1bb528' }}></div>
                            <p className="description">{'Días conciliados'}</p>
                        </div>
                        <div className="legend">
                            <div className="circle" style={{ backgroundColor: 'red' }}></div>
                            <p className="description">{'Días con  novedades'}</p>
                        </div>

                </Grid>
                <Grid item xs={12} md={4} lg={4} >
                    <InputLabel
                        id="demo-simple-select-label">
                        Departamento
                    </InputLabel>
                    <Input
                        readOnly
                        value={form.departamento}
                        id="demo-simple-select-label"
                        style={{ width: '100%' }}
                        size='large'
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4} >
                    <InputLabel
                        id="demo-simple-select-label"
                    >
                        Ciudad
                    </InputLabel>
                    <Input
                        readOnly
                        value={form.ciudad}
                        id="demo-simple-select-label"
                        style={{ width: '100%' }}
                        size='large'
                    />
                </Grid>
                <Grid item xs={12} md={4} lg={4} style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                    <Button size='large' onClick={onSumbit} loading={loading} style={{ width: '100%', fontWeight: 'bold', color: 'red' }}>
                    <i className="zmdi zmdi-search zmdi-hc-fw" style={{ color: 'red', }} />
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default FormularioConciliadorPDV
