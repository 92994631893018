import Mjs from 'components/Mjs/Mjs'
import React, { useEffect, useState } from 'react'
import { obtenerPermisosModulos } from 'util/funciones'
import Storage from 'util/store/Storage'

const ModuleAllowed = ({ children, nameModule }: any) => {
    const [permisos, setpermisos] = useState<string>('')
    const { usuario } = Storage.getState()


    useEffect(() => {
        const permisos: string = obtenerPermisosModulos(usuario, nameModule)
        setpermisos(permisos)
    }, [])


    return (
        permisos.includes('R') ?
            children :
            <Mjs mensaje={'No tienes permisos para acceder a este modulo'} />

    )
}

export default ModuleAllowed
