import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';

export default {
  palette: {
    primary: {
      light: '#302f2f',
      main: '#302f2f',
      dark: '#302f2f',
      contrastText: '#fff'
    },
    secondary: {
      light: '#C9961A',
      main: '#C9961A',
      dark: '#C9961A',
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange',
  },

  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    },
    fontFamily:'exoFont'
  },
};
