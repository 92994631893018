import React from 'react'
import './index.css'


console.log('dev')
const Mjs = ({mensaje}:any) => (
    <div aria-label='prueba-div' className='mjs text-center'>
        <p aria-label='prueba-parrafo'>{mensaje}</p>
    </div>
)

export default Mjs
