export function useSessionStorage() {

    const getSessionStorage = (name: string) => {
        const getInfo = sessionStorage.getItem(name);
        if (getInfo !== null) {
            //console.log("Get session")
            return JSON.parse(getInfo)
        } else return 'N'
    }

    const setSessionStorage = (name: string, data: any) => sessionStorage.setItem(name, JSON.stringify(data));

    const clearSessionStorage = () => sessionStorage.clear();

    const clearKeySessionStorage = (name: string) => sessionStorage.removeItem(name);

    return {
        getSessionStorage,
        setSessionStorage,
        clearSessionStorage,
        clearKeySessionStorage
    }
}
