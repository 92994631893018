import React, { useEffect } from 'react'
//panel del filtro
import { getChartsData } from '../ChartHelpers/Charts.services'
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import { setearMiles } from "util/funciones"
import { getLast7Days } from '../ChartHelpers/Time.helper';
import { getSeriesVentasCombustible } from '../ChartHelpers/Chart.helper';
import { formatearFecha, formatosFecha } from 'util/formatDate';
window.Highcharts = Highcharts;
HCSoldGauge(Highcharts);
const DetallesVenta = ({ updateData, identificadorEstacion }: any): React.ReactElement => {
    useEffect(() => {
        getData()
    }, [updateData,identificadorEstacion])

    const getData = async (): Promise<void> => {
        const UltimosDias = getLast7Days()
        const body = {
            identificadorEstacion: identificadorEstacion,
            extra: UltimosDias
        }
        const data = await getChartsData('ventas_ultimos_7_dias_lite', body)
        const categories = data.map(({ fecha }) => (formatearFecha(fecha, formatosFecha.fecha)))
        const series = getSeriesVentasCombustible(data)
        construirGrafico(categories, series)
    }



    const construirGrafico = (categories: string[], series: any[]): void => {
        Highcharts.chart("ventas-combustible-7-dias", {
            chart: {
                type: "column",
            },
            title: {
                text: "",
            },
            xAxis: {
                categories: categories,
                crosshair: true,
            },
            yAxis: {
                min: 0,
                title: {
                    text: "VENTAS DE COMBUSTIBLE ULTIMOS 7 DIAS",
                },
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return '<b>' + this.point.category + '</b>:  $' + setearMiles(this.point.y);
                }
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                }/*,
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '$ {point.y:.1f}'
                    }
                }*/
            },
            series: series,
        });
    }


    return (
        <>
            <HeaderTitle title={'VENTAS DE COMBUSTIBLE ULTIMOS 7 DIAS'} />
            <div
                id="ventas-combustible-7-dias"
                className="col-md-11 mx-auto"
            /*style={
                isShow
                    ? { marginTop: "15px", display: "block" }
                    : { display: "none" }
            }*/
            ></div>
        </>
    )
}
export default DetallesVenta
