import React, { useState } from 'react'
import Calendar from 'react-calendar';
import { IpropsCalendarCustom } from './interfaces'
import { Popover, Button } from '@material-ui/core'

import './Calendar.css'
import { CalendarToday } from '@material-ui/icons';
import { getDateTimes } from './CalendatCustom.utils';
import { Spin } from 'antd';

const CalendarCustom = ({ onChange, value, dateSucess, dateDanger, spinning = false, minDate}: IpropsCalendarCustom) => {
    const [openPoput, setOpenPoput] = useState(false)
    const [date, setDate] = useState(new Date())
    const [anchorEl, setAnchorEl] = React.useState(null);



    const setDias = ({ activeStartDate, date, view }: any) => {
        try {
            const datesTimesConsolidado = getDateTimes(dateSucess)
            const datesTimesNoConsolidado = getDateTimes(dateDanger)
            if (view == 'month') {
                if (datesTimesConsolidado.includes(date.getTime())) return 'cosolidado'
                if (datesTimesNoConsolidado.includes(date.getTime())) return 'noConsolidado'

            }
            return null
        } catch (error) {
            return null
        }

    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (e: any) => {
        onChange(e)
        setDate(e)
        setOpenPoput(false)
    }



    return (
        <>
            <Button id='calendar' data-testid="btn_calendar" className='btn-grad' aria-describedby='pickerDate' variant="outlined" onClick={(e) => { setOpenPoput(true); handleClick(e) }} startIcon={<CalendarToday />} fullWidth>
                {date.toLocaleDateString('es')}
            </Button>
            <Popover
                id='pickerDate'
                data-testid="popover"
                open={openPoput}
                anchorEl={anchorEl}
                onClose={() => setOpenPoput(false)}

                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <Spin spinning={spinning} >
                    <Calendar data-testid="calendar-mock" minDate={minDate} onChange={handleChange} value={value} maxDate={new Date()} tileClassName={(params) => setDias(params)} />

                </Spin>
            </Popover>
        </>
    )
}

export default CalendarCustom
