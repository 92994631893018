import { AxiosCli } from "./axios-cli";
import {
  APP_HOST,
  PORT_CENTURY,
  PORT_MOVIMIENTO
} from '../constants';



const dataProvider1 = new AxiosCli({
  //path: "/api",
  base_path: APP_HOST,
  port: PORT_CENTURY,
  auth_url: "authentication",
});
const dataProvider2 = new AxiosCli({
  path: "/api/v2",
  base_path: APP_HOST,
  port: PORT_CENTURY,
  auth_url: "authentication",
});

const dataProviderHOMovimiento = new AxiosCli({
  path: "/api/soporte",
  base_path: APP_HOST,
  port: PORT_MOVIMIENTO,
  auth_url: "authentication",
});

export const getDataProvider = (service?: string) => {
  if (typeof service === "string"){

    if (service?.startsWith("/api/v2")) {
      return dataProvider2.service(service);
    }
    if (service?.startsWith("api/soporte")) {
      return dataProviderHOMovimiento.service(service);
    }
  }
  return dataProvider1.service(service);
};
export const service = (service: string) => getDataProvider(service);
export const getService = (service: string) => getDataProvider(service);
export const Logout = (service: string) => {
  const dataProvider = getService(service);
  return dataProvider.signOut;
};
export const reAuthenticate = (params?: any) => {
  if (params) {
    let { path } = params;
    if (typeof path === "string")
      if (path?.startsWith("api2/")) {
        return dataProvider2.reAuthenticate();
      }
  }
  return dataProvider1.reAuthenticate();
};
export const authenticate = ({ path, ...params }: any) => {
  if (typeof path === "string")
    if (path?.startsWith("api2/")) {
      return dataProvider2.authenticate(params);
    }
  return dataProvider1.authenticate(params);
};
