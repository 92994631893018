import React from 'react'
import './index.css'
import { Spin } from 'antd'
interface IProps {
    text?: string
}
const LoaderTerpel = ({text='Generando el reporte...'}:IProps) => {
  return (
    <div id="contenedor">
   <Spin size='large'/>
  </div>

  )
}

export default LoaderTerpel
