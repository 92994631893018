import Axios from 'axios';
import moment from 'moment';
import { formatearFecha, formatosFecha } from 'util/formatDate';
import { sessionCaducada, setearMiles } from 'util/funciones';
import Server from 'util/Server';
import Servidor from 'util/Servidor';

const calcularPorcentaje = (datosArr: any[]) => {

    let total = 0;
    let porcentaje: any[] = [];
    datosArr.forEach((dat) => {
        total += parseInt(dat);
    });
    datosArr.forEach((dat) => {
        porcentaje.push((parseInt(dat) * 100) / total);
    });
    return porcentaje;
}
export const alertaSecadoVirtual = async (identificadorEstacion: string) => {
    const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion as string,
        identificadordispositivo: Servidor.aplicacion as string,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion
    };
    return new Promise(async function (resolve, reject) {
        Axios.post(Servidor.host + Servidor.puertoMovimiento + "/api/reportes/dashboard-lite/ALERTA_TANQUES", data, { headers: headers })
            .then((res) => {
                if (res.status === Servidor.responseOK) {
                    const datos = res.data.data;
                    let tanques = []
                    if (datos) {
                        tanques = datos
                    }
                    resolve(tanques)
                } else if (res.status === Servidor.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                reject(err)
            })
    })
};
export const obtenerJornadasAbiertas = async (identificadorEstacion: string) => {
    const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion as string,
        identificadordispositivo: Servidor.aplicacion as string,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion
    };




    return new Promise(async function (resolve, reject) {
        const res = await Axios.post(
            Servidor.host +
            Servidor.puertoMovimiento +
            "/api/reportes/dashboard-lite/alerta_jornadas_abiertas",
            data,
            { headers: headers }
        );
        let porcentaje = calcularPorcentaje(
            res.data.data.map((dat: any) => setearMiles(dat.num_ventas))
        );
        const datos: any[] = [];
        let porcen = 0;
        res.data.data.forEach((dat: any, i: number) => {
            porcen = porcentaje[i];
            datos.push({
                ...dat,
                porcentaje: porcen,
                hora: moment(dat.fecha_inicio, 'YYYY-MM-DDTHH:mm:ss').format('MMMM DD YYYY, h:mm A').toUpperCase()
            });
        });
        resolve(datos)
    })


};

export const obtenerConsecutivo = async (identificadorEstacion: string) => {
    const headers = {
        Authorization: Servidor.obtenerToken(),
        versionapp: Servidor.versionApp,
        versioncode: Servidor.versionCode,
        fecha: moment().format(),
        aplicacion: Servidor.aplicacion as string,
        identificadordispositivo: Servidor.aplicacion as string,
    };
    const data = {
        fecha_inicial: moment(new Date()).format("YYYY-MM-DD 00:00:00"),
        fecha_final: moment(new Date()).format("YYYY-MM-DD 24:00:00"),
        identificadorEstacion
    };




    return new Promise(async function (resolve, reject) {
        const res = await Axios.post(
            Servidor.host +
            Servidor.puertoMovimiento +
            "/api/reportes/dashboard-lite/alerta_consecutivos",
            data,
            { headers: headers }
        );
        let data1: any[] = [];
        res.data.data.forEach((dat: any) => {
            data1.push({
                ...dat,
                fecha_inicio: moment(dat.fecha_inicio).format("DD/MM/YYYY"),
                fecha_fin: moment(dat.fecha_fin).format("DD/MM/YYYY"),
                consecutivo_restante:
                    parseInt(dat.consecutivo_final) - parseInt(dat.consecutivo_actual),

            });
        });
        resolve(data1)
    })


};

export const obtenerOrdenCompra = async (identificadorEstacion: string) => {
    if (!identificadorEstacion) {
        return Promise.resolve(<any>[])
    }
    return new Promise(async function (resolve, reject) {
        const res = await Axios.get(
            Servidor.host + Servidor.puertoCentury + "/api/movimiento/orden-compra-activa/" + identificadorEstacion,
            { headers: { Authorization: Servidor.obtenerToken() } }
        );
        const data: any[] = [];
        res.data.mensaje.map((dat: any) => (
            data.push({
                dat,
                fecha: moment(dat.fecha).format("YYYY-MM-DD"),
                costo_total: "$ " + setearMiles(dat.costo_total),
            })
            /* (dat.fecha = moment(dat.fecha).format("YYYY-MM-DD"))
             (dat.costo_total = "$ " + setearMiles(dat.costo_total))*/
        ));

        resolve(res.data.mensaje)
    })
};

export const getAlertaDiaVenta = async (identificadorEstacion: number) => {
    return new Promise(async (resolve, reject) => {
        const fecha_inicial = `${formatearFecha(new Date(), formatosFecha.fechaServidor)}`;
        const fecha_final = `${formatearFecha(new Date(), formatosFecha.fechaServidor)}`;
        const res = await Axios.post(
            `${Servidor.host + Servidor.puertoMovimiento}/api/reportes/dashboard-lite/ALERTA_DIA_VENTAS`,
            { fecha_inicial, fecha_final, identificadorEstacion },
            { headers: { Authorization: Servidor.obtenerToken() } }
        );
        const data: any = [];
        data.map((dato: any) => (data.push({
            dato,
            fecha: moment(dato.fecha).format("YYYY-MM-DD"),
            identificadorEstacion: dato.id,
            alias: dato.alias,
            total_ventas_combustible: '$ ' + dato.total_ventas_combustible,
            total_ventas_combustible_productos: '$ ' + dato.total_ventas_combustible_productos,
            total_ventas_combustible_medios_pagos: '$ ' + dato.total_ventas_combustible_medios_pagos,
        })))
        resolve(res)
    })

}

export const getVentasContingencia = async (estaciones: number, fechaInicial: string, fechaFinal: string) => {
    return Axios.post(
        `${Server.host}${Server.puertoMovimiento}/api/movimiento/contingencia`,
        { estaciones, fecha_inicial: fechaInicial, fecha_final: fechaFinal },
        { headers: { Authorization: Server.obtenerToken() } }
    )
}
