import Axios from 'axios'
import server from 'util/Server'
import moment from 'moment';
import Storage from 'util/store/Storage'
import { NotificationManager } from 'react-notifications';
import { sessionCaducada } from 'util/funciones'
import Swal from 'sweetalert2'
import './index.css'


const { usuario: { empresas_id } } = Storage.getState()
export const getEmpresasID = (empresasHijos) => {

  return new Promise((resolve, reject) => {
    Axios.get(server.host + server.puertoCentury + `/api/empresa/select/empresa/${empresas_id == '19' ? '3,5/' : ''}` + empresasHijos, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          resolve(res.data)
        }
      })
      .catch((err) => {
        console.error(err);
        reject(err, undefined);
      })
  })
}

export const getPersonas = async () => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/persona", { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data.personas
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([])
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })

}

export const getPersonasByEmpresasID = async (id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/persona/empresa/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data
          resolve(datos)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([])
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })

}

export const getUsuarioByPersonaID = async (persona_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/persona/usuario/" + persona_id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          const datos = res.data
          if (datos !== null && datos.length > 0) {
            resolve(datos)
          } else {
            resolve([])
          }
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
        }
      })
      .catch(() => reject([]))
  })
}

export const getUltimasPersonasByEmpresasID = async (id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/persona/ultimos/empresa/" + id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          resolve(res.data)
        } else if (res.status === server.responseSesionCaducada) {
          resolve([])
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })

}

export const deletePersonas = async (id, estado) => {
  const { usuario } = Storage.getState()
  Storage.dispatch({
    type: "FORMULARIO_EXTERNO",
    formularioExterno: false,
    metodoFormulario: "",
    formularioId: 0
  });
  const headers = {
    Authorization: server.obtenerToken(),
    versionapp: server.versionApp,
    versioncode: server.versionCode,
    fecha: moment().format(),
    aplicacion: server.aplicacion,
    identificadordispositivo: server.aplicacion
  }
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + "/api/persona/" + id + "/" + estado, {
      create_user: usuario.p_id,
      empresas_id: usuario.empresas_id
    }, { headers: headers })
      .then((res) => {
        if (res.status === server.responseSucces) {
          //swal("OK", "USUARIO "+(estado === "A" ? "ACTIVADO" : "INACTIVADO")+" CORRECTAMENTE", "success")
          if (estado === "A") {
            NotificationManager.success("USUARIO " + (estado === "A" ? "ACTIVADO" : "INACTIVADO") + " CORRECTAMENTE", "OK!");
          } else {
            NotificationManager.error("USUARIO " + (estado === "A" ? "ACTIVADO" : "INACTIVADO") + " CORRECTAMENTE", "OK!");
          }

          Storage.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: true
          });
          resolve(true)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })

}
export const agregarIndicador = async (id, estado) => {
  Storage.dispatch({
    type: "FORMULARIO_EXTERNO",
    formularioExterno: false,
    metodoFormulario: "",
    formularioId: 0
  });
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + "/api/persona/" + id + "/" + estado, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          //swal("OK", "USUARIO "+(estado === "A" ? "ACTIVADO" : "INACTIVADO")+" CORRECTAMENTE", "success")
          if (estado === "A") {
            NotificationManager.success("USUARIO " + (estado === "A" ? "ACTIVADO" : "INACTIVADO") + " CORRECTAMENTE", "OK!");
          } else {
            NotificationManager.warning("USUARIO " + (estado === "A" ? "ACTIVADO" : "INACTIVADO") + " CORRECTAMENTE", "OK!");
          }
          Storage.dispatch({
            type: "ACTUALIZACION_DATOS",
            actualizarvista: true
          });
          resolve(true)
        } else if (res.status === server.responseSesionCaducada) {
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err)
        reject(err, undefined)
      })
  })

}
export const actualizarClaveServer = async (datos) => {
  return new Promise(function (resolve, reject) {
    Axios.post(server.host + server.puertoCentury + '/api/persona/cambiar-clave', datos, { headers: { Authorization: server.obtenerToken() } })
      .then((Response) => {
        if (Response.status === server.responseSucces) {
          Swal.fire({
            title: 'OK!',
            text: Response.data.mensaje,
            icon: 'success',
            customClass: {
              container: 'my-swal'
            }
          }).then(resolve(true))
        } else if (Response.status === server.responseSesionCaducada) {
          sessionCaducada()
        } else {
          resolve(false)
          Swal.fire('ERROR!', Response.data.mensaje, 'warning')
        }
      })
      .catch((err) => {
        console.error(err)
        Swal.fire({
          title: 'ERROR!',
           text: err.response.data.mensaje,
           icon: 'warning',
           customClass: {
            container: 'my-swal'
          }
        })
        reject(false)
      })
  })

}

export const guardarUsuario = async (datos) => {
  return new Promise(function (resolve, reject) {
    Axios.post(server.host + server.puertoCentury + "/api/persona/create-user", datos, { headers: { Authorization: server.obtenerToken() } })
      .then(Response => {
        if (Response.status === server.responseSucces) {
          if (Response.data.success) {
            Swal.fire({
              title: "OK!",
              text: Response.data.mensaje,
              icon: "success",
            }).then(() => {
              Storage.dispatch({
                type: "ACTUALIZACION_DATOS",
                actualizarvista: true
              });
              resolve(true)
            });
          } else {
            Swal.fire("ERROR!", Response.data.mensaje, "warning");
            resolve(false)
          }
        } else if (Response.status === server.responseSesionCaducada) {
          sessionCaducada()
        }
      })
      .catch((err) => {
        console.error(err)
        reject(false)
      })
  })
}

export const acutalizarPin = async (datos) => {
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + "/api/persona/cambiar-pin", datos, { headers: { Authorization: server.obtenerToken() } })
      .then(Response => {
        if (Response.status === server.responseOK) {
          Swal.fire({
            title: "OK!",
            text: Response.data.mensaje,
            icon: "success",
          }).then(() => {
            Storage.dispatch({
              type: "ACTUALIZACION_DATOS",
              actualizarvista: true
            });
          }).then(resolve(true));
        } else if (Response.status === server.responseSesionCaducada) {
          sessionCaducada()
        } else {
          Swal.fire("ERROR!", Response.data.mensaje, "warning");
          resolve(false)
        }
      })
      .catch((err) => {
        console.error(err)
        reject(false)
      })
  })
}



export const actualizarIdentificadoresUsuario = async (data) => {
  return new Promise(function (resolve, reject) {
    Axios.put(server.host + server.puertoCentury + "/api/persona/identificadores", data, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseSucces) {
          Swal.fire("OK", "IDENTIFICADORES ACTUALIZADOS CORRECTAMENTE", "success")
          resolve(true)
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.log(err)
        reject(false)
      })
  })
}

export const obtenerIdentificadores = async (persona_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/persona/identificadores/" + persona_id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          resolve(res.data.data)
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.log(err)
        reject([])
      })
  })
}

export const getPaises = async (negocioId) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/empresa/select/atributosbyentidad/empresa/9/${negocioId}`,
    { headers: { Authorization: server.obtenerToken() } })
}

export const getDepartamentoByPais = async (paisId, negocioId) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/empresa/select/atributosbyatributo/empresa/${paisId}/${negocioId}`,
    { headers: { Authorization: server.obtenerToken() } })
}

export const getCiudadesByDeparamento = async (departamentoId, negocioId) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/empresa/select/atributosbyatributo/empresa/${departamentoId}/${negocioId}`,
    { headers: { Authorization: server.obtenerToken() } })
}

export const getPerfiles = async (tipoUsuario, negocioId) => {
  let tipoPerfiles = ''
  switch (tipoUsuario) {
    case 1:
      tipoPerfiles = '1,2,3,4,5,6,7,8'
      break
    case 2:
    case 3:
      tipoPerfiles = '2,3,4,5,7,8'
      break
    case 4:
    case 5:
      tipoPerfiles = '4,5,7,8'
      break
  }
  return Axios.get(`${server.host}${server.puertoCentury}/api/perfil/tipo/${tipoPerfiles}/empresa/${negocioId}`,
    { headers: { Authorization: server.obtenerToken() } })
}

export const crearEmpleado = async (data) => {
  return Axios.post(`${server.host}${server.puertoCentury}/api/persona`, data,
    { headers: { Authorization: server.obtenerToken() } })
}

export const actualizarEmpleado = async (data) => {
  return Axios.put(`${server.host}${server.puertoCentury}/api/persona`, data,
    { headers: { Authorization: server.obtenerToken() } })
}

export const getPersonaById = async (id) => {
  return Axios.get(`${server.host}${server.puertoCentury}/api/persona/${id}`,
    { headers: { Authorization: server.obtenerToken() } })
}
