export const TIPOS_PRODUCTOS_LABEL = {
  CANASTILLA: "CANSTILLA",
  COMBUSTIBLE: "COMBUSTIBLE",
  SERVICIOS: "SERVICIOS"
};

export const TIPOS_COMBUSTIBLES = {
  CORRIENTE: 27,
  EXTRA: 28,
  DIESEL: 29,
  GAS: 30,
  GLP: 33,
  ADBLUE: 33
}

export const TIPOS_CANASTILLAS = {
  NORMAL: 23,
  INGREDIENTES: 24,
  COMPUESTOS: 25,
  PRODUCIDOS: 26,
  COMBO: 32,
}

export const TIPOS_SERVICIOS = {
  DEFAULT: 22
}

export const TIPOS_GRUPOS = {
  CATEGORIAS: 14,
  TIPOS_VEHICULOS: 18,
  TIPOS_SERVICIOS: 47,
  FLOTAS_VEHICULARES: 12,
  TIPOS_EQUIPOS: 35
}

export const PARAMETROS_PLATAFORMA = {
  EDICION_DE_VENTAS: 'EDICION_DE_VENTAS',
  LOGO_PROPIO: 'LOGO_PROPIO',
  DESCRIPCION_EDICION_VENTA: 'DESCRIPCION_EDICION_VENTA',
}

export const IDENTIFICADORES_ORIGENES = {
  PRODUCTOS_PLU: 1,
  PRODUCTOS_CODIGOS_BARRAS: 2,
  PERSONAS_TAG: 3,
  VEHICULOS_IDROM: 4,
  VEHICULOS_TAG: 5,
  CENTRO_LOGISTICO: 1000,
  CENTRO_BENEFICIO: 1001,
  PERSONAS_IDROM:1010
}

export const ENTIDADES_ID = {
  PARAMETROS: 1,
  EMPRESAS: 2,
  PERSONAS: 3,
  VEHICULOS: 4,
  PAIS: 9,
  PROVINCIA: 11,
  FLOTAS_EMPRESARIALES: 12,
  PRODUCTOS: 13,
  CLASIFICACION_PRODUCTOS: 14,
  SURTIDORES: 15,
  PROTOCOLOS_SURTIDORES: 16,
  TIPOS_PRODUCTOS: 19,
  PRODUCTOS_CANASTILLA: 20,
  PRODUCTOS_COMBUSTIBLES: 21,
  SERVICIOS: 22
}

export const DOMINIOS = {
  DATAGAS: 'DATAGAS',
  FUELLY: 'FUELLY',
  TERPEL: 'TERPEL',
  FIREFUEL: 'FIREFUEL',
}

export const TABLAS = {
  NO_DATOS: 'No se encuentran datos'
}
