import Axios from 'axios'
import Servidor from 'util/Servidor'

export const saveAndGetFavorites = async (body: any): Promise<any> => {
    return new Promise(function (resolve, reject) {
        Axios.post(Servidor.host + Servidor.puertoEquipo + "/api/propiedad/acceso-directo", body, { headers: { Authorization: Servidor.obtenerToken() } })
            .then((responsee) => {
                resolve(responsee.data)
            })
            .catch(err => {
                console.error(err)
                reject(err)
            })
    })
}