import LoaderTerpel from 'components/LoaderTerpel/LoaderTerpel'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const AjusteInicial = lazy(async () => import('./AjusteInicial'))
const Entrada = lazy(async () => import('./Entrada'))
const OrdenCompra = lazy(async () => import('./OrdenCompra'))
const ProductosKiosco = lazy(async () => import('./ProductosKiosco'))
const ReporteVentasKiosco = lazy(async () => import('../Reportes/ReporteVentas/ReporteVentasKiosco'))
const ReporteVentasKioscoDetallada = lazy(async () => import('../Reportes/ReporteVentas/ReporteVentasKioscoDetallada'))
const LibroFacturacionKiosco = lazy(async () => import('./LibroFacturacionKiosco'))
const ReportesCompra = lazy(async () => import('./ReportesCompra'))
const AnulacionVentas = lazy(async () => import('./AnulacionVentas'))
const Salidas = lazy(async () => import('./Salidas'))
const CostoYUtilidad = lazy(async () => import('./CostoYUtilidad'))
const Promociones = lazy(async () => import('./Promociones'))
const Traslados = lazy(async () => import('./Traslados'))

const Configuracion = ({ match }: any) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense  fallback={<LoaderTerpel />}>
          <Route path={`${match.url}/ajuste-inicial-kiosco`} component={AjusteInicial} />
          <Route path={`${match.url}/entrada-kiosco`} component={Entrada} />
          <Route path={`${match.url}/ordenes-compra-kiosco`} component={OrdenCompra} />
          <Route path={`${match.url}/productos-kiosco`} component={ProductosKiosco} />
          <Route path={`${match.url}/ventas-kiosco-detallada`} component={ReporteVentasKioscoDetallada} />
          <Route path={`${match.url}/ventas-kiosco`} component={ReporteVentasKiosco} />
          <Route path={`${match.url}/libro-facturacion-kiosco`} component={LibroFacturacionKiosco} />
          <Route path={`${match.url}/libro-compras-kiosco`} component={ReportesCompra} />
          <Route path={`${match.url}/anulacion-venta-kiosco`} component={AnulacionVentas} />
          <Route path={`${match.url}/salida-kiosco`} component={Salidas} />
          <Route path={`${match.url}/costo-utilidad-kiosco`} component={CostoYUtilidad} />
          <Route path={`${match.url}/promociones-kiosco`} component={Promociones} />
          <Route path={`${match.url}/traslados`}>
            <Traslados finalidad={'KIOSCO'} match={{path:`${match.url}/traslados bodegas`}} />
          </Route>
        </Suspense>
      </Switch>
    </div>
  )
}

export default Configuracion
