import { ColumnsDatatable, ColumnsPDF } from 'components/Datatable'
import { DOMINIOS } from 'constants/centuryConstans'
import XLSX from 'xlsx'
import Storage from 'util/store/Storage';

export const LeerExcel = async (archivo: any): Promise<any> => {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = (e: any) => {
      let data = e.target.result
      let readedData = XLSX.read(data, { type: 'binary' })
      const wsname = readedData.SheetNames[0]
      const ws = readedData.Sheets[wsname]

      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 })
      resolve(dataParse)
    }
    reader?.readAsBinaryString(archivo)
  })
}

export const objetoVacio = (objeto: object | null): boolean => {
  if (objeto === null || objeto == undefined|| Object.entries(objeto).length === 0) {
    return true
  }
  return false
}
export const resolverTotalizadores = (array: any[], name: string) => {
  let result = 0;
  array.forEach((item) => {
    result += Number(item[name])
  })
  return result
}
export const numeroValido = (numero: number | string): boolean => {
  if (
    (numero + '').trim().length > 0 &&
    validarExpresionRegular(numero + '', /^\d+([.]\d+)?$/) &&
    !isNaN(parseFloat(numero + '')) &&
    parseFloat(numero + '') > 0
  ) {
    return true
  }
  return false
}

export const esNumero = (numero: number | string): boolean => {
  if (validarExpresionRegular(numero + '', /^-?\d+([.]\d+)?$/)) {
    return true
  }
  return false
}

export const validarExpresionRegular = (str: string, expresionRegular: RegExp): boolean => {
  return expresionRegular.test(str)
}

export const validarCorreo = (correo: string): boolean => {
  const regexNombreCorreo = "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*"
  const regexDominioCorreo = "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
  return validarExpresionRegular(correo, new RegExp(`^${regexNombreCorreo}@${regexDominioCorreo}$`))
}

export const transformDataTableToPdfModel = (columnas: ColumnsDatatable[]): ColumnsPDF[] => {
  return columnas.map(columna => ({
    header: columna.header,
    dataKey: columna.field
  }))
}

export const textoCentradoPDF = (doc: any, text: string, alto: number): any => {
  const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
  const textOffset = (doc.internal.pageSize.width - textWidth) / 2
  return doc.text(textOffset, alto, text)
}

export const EtiquetaBajoTablaPDF = (doc: any): number => {
  return doc.lastAutoTable.finalY + 15
}

export const snakeCaseToSeparatedUppercase = (element: any): any => {

  if (Array.isArray(element)) {
    return element.map(snakeCaseToSeparatedUppercase)
  } else if (typeof element === 'object' && element !== null) {
    const cloneObject: { [key: string]: string } = {}
    const keys = Object.keys(element)
    keys.forEach((key: string) => {
      const separatedKey = key.toUpperCase().replace(/_/g, ' ')
      const value = element[key].props && element[key].props.children !== undefined ? element[key].props.children : element[key];
      cloneObject[separatedKey] = snakeCaseToSeparatedUppercase(value)
    })
    Object.setPrototypeOf(cloneObject, Object.getPrototypeOf(element))
    return cloneObject
  }
  return element
}
export const snakeCaseToSeparatedUppercase2 = (element: any): any => {

  if (Array.isArray(element)) {
    return element.map(snakeCaseToSeparatedUppercase2)
  } else if (typeof element === 'object' && element !== null) {
    const cloneObject: { [key: string]: string } = {}
    const keys = Object.keys(element)
    keys.forEach((key: string) => {
      const separatedKey = key.toUpperCase().replace(/_/g, ' ')
      const value = element[key];
      cloneObject[separatedKey] = snakeCaseToSeparatedUppercase2(value)
    })
    Object.setPrototypeOf(cloneObject, Object.getPrototypeOf(element))
    return cloneObject
  }
  return element
}

export const esDominioDatagas = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.DATAGAS
  } catch (error) {
    return false
  }
}
export const esDominioTerpel = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.TERPEL
  } catch (error) {
    return false
  }
}
export const esDominioFuelly = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.FUELLY
  } catch (error) {
    return false
  }
}
export const esDominioFirefuel = (dominio: any) => {
  try {
    return dominio.atributos.tipo_dominio === DOMINIOS.FIREFUEL
  } catch (error) {
    return false
  }
}
export const resolveError = (error: any, tipoError: 'INTERNAL' | 'VALIDATE'): string => {
  try {
    switch (tipoError) {
      case 'INTERNAL':
        return error.data.mensajeError as string
      case 'VALIDATE':
      default:
        return error.data.mensaje as string
    }
  } catch (error) {
    return 'Ha ocurrido un error'
  }
}

export const Logger = (mensaje: string, descripcion?: string) => {
  console.log('-------------------------------------------')
  console.log(`             ${descripcion}                `)
  console.log(`${mensaje}`)
  console.log('-------------------------------------------')
}

export const LoggerError = (mensaje: string, funcion?: string, archivo?: string) => {
  console.log('-------------------------------------------')
  console.log(`%cError en ${archivo ?? ''}.${funcion ?? ''}`, 'color: red; font-size: 20px')
  console.log(`%c${mensaje}`, 'color:red')
  console.log('-------------------------------------------')
}

export const cerrarFormularioExterno = () => {
  Storage.dispatch({
    type: 'FORMULARIO_EXTERNO',
    formularioExterno: false,
    metodoFormulario: '',
    formularioId: 0
  })
}

interface IContactos {
  contacto: string
  tipo: number
  principal: string
  descripcion: string
}
export const obtenerContacto = (contactos: IContactos[], tipo: number): string => {
  try {
    return contactos.find((contacto) => contacto.tipo === tipo)?.contacto ?? 'N/A'
  } catch (error) {
    return ''
  }
}

export const setearMiles = (numero: string | number) => {
  if (Number.isNaN(numero)) return 0
  return new Intl.NumberFormat('de-DE').format(parseFloat(`${numero}`).toFixed(2) as any)
}

export const valueValid = (cadena: any): string | '' => {
  let result: string = ''
  if (typeof cadena === 'undefined' || typeof cadena === null) { return result}
  else{
    return cadena
  }
}

export const setearMiles2 = (numeroAsetear:number,nDecimales:number,  separadorMiles:string, indicadorDecimales:string) => {
  let re = '\\d(?=(\\d{' + (3) + '})+' + (nDecimales > 0 ? '\\D' : '$') + ')',
      num = numeroAsetear?.toFixed(Math.max(0, ~~nDecimales));
  return (indicadorDecimales ? num?.replace('.', indicadorDecimales) : num)?.replace(new RegExp(re, 'g'), '$&' + (separadorMiles || ','));
}

