
import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import React, { useState } from 'react'

interface IValue {
  label: string
  value: number
}

interface IOptionsType extends Array<{ value: string; label: string }> { }

interface ISelectLabelAutoProps {
  colMd?: number
  disabled?: boolean
  id?: string | number
  label: string
  testId?: string
  multiple?: boolean
  onChange: (...args: any[]) => any
  opciones: IOptionsType | any[]
  value: any
  variant?: 'filled' | 'outlined' | 'standard'
  disabledValue?: any
  getOptionDisabled?: any
  inputProps?: Object,
  disabledClereabed?: boolean
  fullWidth?: boolean
  completeInput?: boolean

  notShowEmpty?:boolean
  style?:React.CSSProperties
}

const SelectAutoComplete = (props: ISelectLabelAutoProps): React.ReactElement => {
  const {
    colMd = 3,
    disabled = false,
    completeInput = false,
    id = 'selectable-autocomplete',
    label,
    fullWidth = false,
    disabledClereabed = false,
    multiple = false,
    onChange,
    style={width:'100%'},
    opciones = [],
    value = null,
    variant = 'standard',
    disabledValue = null,
    inputProps = {},
    notShowEmpty = false,
    getOptionDisabled = (option: { label: string; value: number }): boolean => option === disabledValue,
    testId = 'test-uid',
  } = props

  const handleOnChange = (value: string): any => {
    if (onChange !== undefined) onChange(value)
    return null
  }

  const resolveValue = () => {
    try {
      return value
    } catch (error) {
      return null
    }
  }

  const [valueInput, setValueInput] = useState('')


  return (
    <div style={{paddingInline:'5px'}} className={`col-md-${colMd} boxSelect`}>
      <Autocomplete
        data-testid={testId}
        fullWidth={fullWidth}
        autoComplete={false}
        disabled={disabled}
        filterSelectedOptions
        getOptionLabel={(option: { label: string; value: number }) => option.label ?? ''}
        id={`${id}`}
        disableClearable={disabledClereabed}
        includeInputInList

        open={notShowEmpty ? opciones.length == 0 ? false : undefined : undefined}
        multiple={multiple}
        onChange={(event: any, value: any) => {
          if (value !== null) {
            handleOnChange(value)
            setValueInput('')
            
          } else {
            handleOnChange({} as any)
          }
        }}
        options={opciones as any}
        renderInput={(params) => {
          params.inputProps = {...params.inputProps, ...inputProps };

          return ( <TextField {...params} label={label} onBlur={()=>{
            if(completeInput && valueInput.trim() != ''){
              handleOnChange({
                value:valueInput,
                label:valueInput
              } as any)
            }
          }} onKeyDown={(e)=>console.log('lee',e.key,e.altKey,e.ctrlKey,e.code)} onChange={(e)=>setValueInput(e.target.value)} variant={variant} /> )
        }}
        value={resolveValue()}
        style={style}
        getOptionDisabled={getOptionDisabled}
      />
    </div>
  )
}

export default SelectAutoComplete
