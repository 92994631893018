import React from 'react'

const EnviarIcon = ({color='none'}:any) => {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill={color} xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.41975 2.29101C1.41975 1.68502 1.89901 1.19326 2.49037 1.19326C3.08173 1.19326 3.56099 1.68502 3.56099 2.29101V11.3946C3.56099 12.6955 4.58916 13.75 5.8574 13.75H15.0401C15.2941 13.75 15.5 13.5388 15.5 13.2784C15.5 13.0179 15.2941 12.8067 15.0401 12.8067C14.2797 12.8067 13.6632 12.1745 13.6632 11.3946V2.29101C13.6632 1.16411 12.7722 0.25 11.6731 0.25H5.55112V1.19326H11.6731C12.2642 1.19326 12.7435 1.68497 12.7435 2.29101V11.3946C12.7435 11.9244 12.914 12.4133 13.2018 12.8067H5.8574C5.0972 12.8067 4.48073 12.1746 4.48073 11.3946V2.29101C4.48073 1.16407 3.58969 0.25 2.49037 0.25C1.39104 0.25 0.5 1.16407 0.5 2.29101C0.5 3.41835 1.39095 4.33251 2.49037 4.33251V3.38924C1.8991 3.38924 1.41975 2.89759 1.41975 2.29101ZM5.55135 4.33322H11.6731V3.38995H5.55135V4.33322ZM11.6731 7.47198H5.55135V6.52872H11.6731V7.47198ZM5.55135 10.6108H11.6731V9.66751H5.55135V10.6108Z" fill="white"/>
</svg>

  )
}

export default EnviarIcon