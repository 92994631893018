import React, { useEffect, useState } from 'react'
//panel del filtro
import HeaderTitle from './Components/HeaderTitle'
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge';
import { setearMiles } from "util/funciones"
import { determinateDate } from '../ChartHelpers/Chart.helper';
import FiltrosFecha from './Components/FiltrosFecha';
import { getGraficaCombinada } from '../ChartHelpers/Charts.services';
window.Highcharts = Highcharts;
HCSoldGauge(Highcharts);
const ChartIslasAndProductos = ({ updateData, identificadorEstacion, identificadorNegocio }: any): React.ReactElement => {
    const [selectionDate, setSelectionDate] = useState(0)

    useEffect(() => {
        getData()
    }, [updateData, selectionDate,identificadorEstacion])

    const getData = async (): Promise<void> => {
        const { fechaInicial, fechaFinal } = determinateDate(selectionDate)
        const body = {
            fecha_inicial: fechaInicial,
            fecha_final: fechaFinal,
            identificadorEstacion: identificadorEstacion,
            identificadorNegocio: identificadorNegocio
        };
        const { islas, surtidores } = await getGraficaCombinada(body)
        construirGrafico(islas, surtidores)
    }

    const construirGrafico = (islas: any[], surtidores: any[]) => {
        Highcharts.chart("ventas-islas-x-productos", {
            title: {
                text: "",
            },
            xAxis: {
                categories: islas,
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return ' $' + setearMiles(this.point.y);
                }
            },
            labels: {
                items: [
                    {
                        html: "VENTAS POR PRODUCTOS",
                        style: {
                            left: "50px",
                            top: "0px",
                            color: "black"
                        },
                    },
                ],

            },
            series: Array.isArray(surtidores) ? surtidores.filter(value => value !== null) : [],
        } as any);
    }


    return (
        <>
            <HeaderTitle title={'VENTAS POR ISLAS Y PRODUCTOS'} />
            <FiltrosFecha onChange={(selection: number) => setSelectionDate(selection)} />
            <div
                id="ventas-islas-x-productos"
                className="col-md-11 mx-auto"
            /*style={
                isShow
                    ? { marginTop: "15px", display: "block" }
                    : { display: "none" }
            }*/
            ></div>
        </>
    )
}
export default ChartIslasAndProductos
