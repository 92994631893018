import Loader from 'components/Loader'
import LoaderTerpel from 'components/LoaderTerpel/LoaderTerpel'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
const AuditoriaEds = lazy(async () => import('../Configuracion/AuditoriaEds'))

const Bitacora = lazy(async () => import('./Bitacora'))
const PlanillaTurno = lazy(async () => import('./PlanillaTurno'))


const Eds = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<LoaderTerpel />}>
          <Route path={`${match.url}/auditoriaeds`} component={AuditoriaEds} />
          <Route path={`${match.url}/bitacorajornada`} component={Bitacora} />
          <Route path={`${match.url}/planillaturnos`} component={PlanillaTurno} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Eds
