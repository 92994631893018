/**
 * Generate a random safely
 * Jose Luis Ospino
 * 06/09/2022
 */
const generateNumberRandom = () => {
    const crypto = window.crypto || window.msCrypto;
    let array = new Uint32Array(1);
    crypto.getRandomValues(array); // Compliant for security-sensitive use cases
    const random = array[0];
    return random;
}

export default generateNumberRandom;