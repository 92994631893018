import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router'
import Loader from 'components/Loader'

const DepuracionPos = lazy(async () => import('./DepuracionPos/DepuracionPos'))

const Mantenimiento = ({ match }: any) => {

    return (
        <div className="app-wrapper">
            <Switch>

                <Suspense fallback={<Loader />}>
                    <Route path={`${match.url}/depuracionpos`} component={DepuracionPos} />
                </Suspense>
            </Switch>
        </div>
    )

}
export default Mantenimiento