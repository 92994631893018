import { AllowedModuleContex } from 'context/AllowedModuleContex';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { obtenerPermisosByPath } from 'util/funciones';


export function usePermission() {
  return useContext(AllowedModuleContex);
}

const AllowedModuleProvider = ({ children }: any) => {
  const jsonString = '{"empresa": "default"}';
  const userStorage = sessionStorage.getItem('currentUser') ?? jsonString;
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState('');
  const [currentUser, setCurrentUser] = useState(userStorage);
  const [permisos, setPermisos] = useState('');

  useEffect(() => {
    setCurrentPath(location.pathname);
    setCurrentUser(userStorage)
    localStorage.setItem('currentPath', location.pathname);

  }, [location.pathname, userStorage]);


  useEffect(() => {
    const permisos = obtenerPermisosByPath(JSON.parse(currentUser), currentPath);
    setPermisos(permisos);
  }, [currentPath, userStorage]);


  return (
    <AllowedModuleContex.Provider value={permisos}>
      {children}
    </AllowedModuleContex.Provider>
  );
}

export default AllowedModuleProvider;
