import axios from "axios";
import { ApiType } from "./api.dto";
import server from 'util/Server';
export class AxiosCli {
  static accessToken: string = "";
  static refreshToken: string = "";
  request: any;
  path?: string;
  base_path?: string;
  port?: string;
  auth_url: string;
  refresh_url?: string;
  url: string = "";
  storageKey: string = "user";
  accessTokenKey: string = "accessToken";
  refreshTokenKey: string = "accessToken";
  usernameField: string = "email";
  passwordField: string = "password";

  constructor({
    base_path,
    port,
    path,
    auth_url,
    refresh_url,
    storageKey,
    accessTokenKey,
    refreshTokenKey,
    usernameField,
    passwordField,
  }: ApiType) {

    this.request = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
      },
      //baseURL: `${base_path}${port ? ":" + port : ""}${path || ""}`,
    });

    this.path=path;
    this.port=port;
    this.base_path=base_path;
    this.auth_url = auth_url;
    this.refresh_url = refresh_url;
    this.storageKey = storageKey ?? this.storageKey;
    this.accessTokenKey = accessTokenKey ?? this.accessTokenKey;
    this.refreshTokenKey = refreshTokenKey ?? this.refreshTokenKey;
    this.usernameField = usernameField ?? this.usernameField;
    this.passwordField = passwordField ?? this.passwordField;
    AxiosCli.accessToken =
      window.localStorage.getItem(this.accessTokenKey) ?? "";
    AxiosCli.refreshToken =
      window.localStorage.getItem(this.refreshTokenKey) ?? "";
  }
  authenticate(params: any) {
    const self = this;
    let { auth_url, accessTokenKey, storageKey, usernameField, passwordField } =
      this;
    let { strategy, port, base_path, path } = params;
    if (base_path)
      auth_url = `${base_path}${port ? ":" + port : ""}${path || ""}`;
    return new Promise(async (resolve, reject) => {
      try {
        if (!auth_url) reject("Auth URL is required");
        let { data } = await self.request.post(auth_url, {
          [usernameField]: params[usernameField],
          [passwordField]: params[passwordField],
          strategy,
        });
        let accessToken = data[accessTokenKey] || data["accessToken"];
        let refreshToken = data[this.refreshTokenKey];
        if (accessToken) {
          AxiosCli.accessToken = accessToken;
          AxiosCli.refreshToken = refreshToken;
          self.request.defaults.headers.common["Authorization"] =
            AxiosCli.accessToken;
          window.localStorage.setItem(this.refreshTokenKey, refreshToken);
          window.localStorage.setItem(this.accessTokenKey, accessToken);
        }
        if (data[storageKey])
          window.localStorage.setItem(
            storageKey,
            JSON.stringify(data[storageKey])
          );
        return resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  }
  reAuthenticate() {
    let { refreshTokenKey, accessTokenKey, storageKey, refresh_url, auth_url } =
      this;
    const self = this;
    return new Promise(async (resolve, reject) => {
      try {
        if (!AxiosCli.accessToken)
          reject(`${this.refreshTokenKey} not found in localStorage.`);
        self.request.defaults.headers.common["Authorization"] =
          AxiosCli.accessToken;
        let { data } = await self.request.post(`/${refresh_url ?? auth_url}`, {
          strategy: "jwt",
          [refreshTokenKey || accessTokenKey]:
            AxiosCli.refreshToken || AxiosCli.accessToken,
        });
        let accessToken = data[accessTokenKey] || data["accessToken"];
        let refreshToken = data[this.refreshTokenKey];
        if (accessToken) {
          AxiosCli.accessToken = accessToken;
          AxiosCli.refreshToken = refreshToken || accessToken;
          this.request.defaults.headers.common["Authorization"] =
            AxiosCli.accessToken;
          window.localStorage.setItem(this.refreshTokenKey, refreshToken);
          window.localStorage.setItem(this.accessTokenKey, accessToken);
        }
        if (data[storageKey])
          window.localStorage.setItem(
            storageKey,
            JSON.stringify(data[storageKey])
          );
        resolve(data);
      } catch (err) {
        console.log("ERROR:", err);
        reject(err);
      }
    });
  }
  signOut() {
    window.localStorage.removeItem(this.refreshTokenKey);
    window.localStorage.removeItem(this.refreshTokenKey);
    window.localStorage.removeItem(this.storageKey);
  }
  service(service?: string) {
    if (!service) {
      console.warn("service not defined");
      return this;
    }
    let _match = service.match(/^(\w+)\//gi);
    this.url = service;

    this.request = axios.create({
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        "Authorization": server.obtenerToken()
      },
      baseURL: `${this.base_path}${this.port ? ":" + this.port : ""}`,
    });
    return this;
  }
  find = async ({ query = {} }: any) => {
    const self = this;

    let { url } = this;
    let params = {
      ...query,
    };
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await self.request.get(url, {
          params,
        });
        return resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  };
  remove = async (id: number) => {
    let { url } = this;
    const self = this;
    return new Promise(async (resolve, reject) => {
      try {
        if (!id) reject("id not found");
        let data = await self.request.delete(`${url}/${id}`);
        if (Array.isArray(data)) return resolve(data);
        return resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  };
  get = async (id: number) => {
    let { url } = this;
    const self = this;
    return new Promise(async (resolve, reject) => {
      try {
        if (!id) reject("id not found");
        let { data } = await self.request.get(`${url}/${id}`);
        if (Array.isArray(data)) return resolve(data);
        return resolve(data);
      } catch (err: any) {
        reject(err);
      }
    });
  };
  patch = async (id: number, params: any = {}) => {
    let { url } = this;
    const self = this;
    return new Promise(async (resolve, reject) => {
      try {
        if (!id) reject("id not found");
        let { data } = await self.request.patch(`${url}/${id}`, params);
        if (Array.isArray(data)) return resolve(data);
        return resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  };
  patchMany = async (params: any = {}) => {
    let { url } = this;
    const self = this;
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await self.request.patch(`${url}`, params);
        if (Array.isArray(data)) return resolve(data);
        return resolve(data);
      } catch (err) {
        reject(err);
      }
    });
  };
  create = async (params: any = {}) => {
    const self = this;
    let { url } = this;
    return new Promise(async (resolve, reject) => {
      try {
        let res = await self.request.post(`${url}`, params);
        const { data } = res;
        if (Array.isArray(data)) return resolve({ data: data });
        return resolve(data);
      } catch (err: any) {
        const errorMessage = err.response.data?.errors?.[0] || err;
        reject(errorMessage);
      }
    });
  };
}
