import { Card, Typography } from 'antd'
import React from 'react'
import './RegionalCard.css'
interface IRegional {
    id_regional_negocio: number;
    nombre_regional: number;
}
interface IRegionalCardProps {
    regional: IRegional
    onEdit:()=>void
}
const RegionalCard = ({ regional,onEdit }: IRegionalCardProps) => {
    return (
        <Card hoverable className='card-regional' >
            <div className='cardRegional'>
                <Typography className='name-regional'>{regional.nombre_regional || 'Nombre'}</Typography>
                <Typography className='regional-btn' onClick={onEdit}>Editar</Typography>
            </div>
        </Card>
    )
}

export default RegionalCard