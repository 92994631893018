import React from 'react'
import { Spin } from 'antd';
const Loader = (): React.ReactElement => {
  return (
    <div style={{ width: '100%' }}>
      <div className="mx-auto w-100 text-center">
        <Spin size='large' />
      </div>
    </div>
  )
}

export default Loader
