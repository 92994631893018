import { ColumnasModalAlertaFE, rutaMonitorFacturacionElectronica } from 'app/vistas/Acciones/FE-noAutorizados/fe.helper'
import DataTableFilterDemo from 'components/Datatable'
import moment from 'moment'
import React from 'react'
import { Button, Modal, ModalProps } from 'react-bootstrap'
import { Link } from 'react-router-dom'

interface ModalAlertaFEProps extends ModalProps {
    facturas: any[]
}
export default function ModalAlertaFacturaElectronica(props: ModalAlertaFEProps) {

    const formatearFacturas = () => {
        return props.facturas.map(factura => ({...factura,
            factura: `${factura.prefijo}${factura.consecutivo}`,
            fecha: moment(factura.fecha).format('DD/MM/YYYY h:mm A')
        }))
    }

    return (
        <Modal {...props}>
            <Modal.Header placeholder='' closeButton>
                <Modal.Title>FACTURAS SIN GESTIONAR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTableFilterDemo data={formatearFacturas()} columns={ColumnasModalAlertaFE} />
            </Modal.Body>
            <Modal.Footer>
                <Link to={rutaMonitorFacturacionElectronica}><Button variant='primary'>Ir al monitor</Button></Link>
            </Modal.Footer>
        </Modal>
    )
}