import moment from "moment";
import { IDatasetMultipleChart, ISelectedDates, ISelectedOperation } from "./Charts.interface";
export const getSeriesVentasCombustible = (ventas: any[]): IDatasetMultipleChart[] => {
    return [
        {
            "name": "CORRIENTE",
            "color": "#E50000",
            "data": ventas.map(({ corriente }) => corriente)
        },
        {
            "name": "DIESEL",
            "color": "#FFE000",
            "data": ventas.map(({ diesel }) => diesel)
        },
        {
            "name": "EXTRA",
            "color": "#0013F9",
            "data": ventas.map(({ extra }) => extra)
        },
        {
            "name": "GAS",
            "color": "#0EC100",
            "data": ventas.map(({ gas }) => gas)
        },
        {
            "name": "GLP",
            "color": "#939393",
            "data": ventas.map(({ glp }) => glp)
        },
        {
            "name": "ADBLUE",
            "color": "#3E9AFF",
            "data": ventas.map(({ adblue }) => adblue)
        }
    ]
}

export const determinateDate = (dateSelection: number): ISelectedDates => {
    let fechaInicial = ""
    let fechaFinal = ""
    switch (dateSelection) {
        case 1:
            fechaInicial = moment(new Date()).subtract(6, "day").format("YYYY-MM-DD 00:00:00")
            fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
            break;
        case 2:
            fechaInicial = moment().date(1).format("YYYY-MM-DD 00:00:00")
            fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
            break;
        case 3:
            fechaInicial = moment(new Date()).format("YYYY-01-01 00:00:00")
            fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
            break;
        case 0:
        default:
            fechaInicial = moment(new Date()).format("YYYY-MM-DD 00:00:00")
            fechaFinal = moment(new Date()).format("YYYY-MM-DD 24:00:00")
            break;
    }
    return { fechaInicial, fechaFinal }
}

export const determinateOperation = (operationSelected: number): ISelectedOperation => {
    let operacion = ""
    let estadoMovimiento = ""
    switch (operationSelected) {
        case 0:
            operacion = `'009'`
            estadoMovimiento = `'009001'`
            break;
        case 1:
            operacion = `'017'`
            estadoMovimiento = `'017001'`
            break;
        case 2:
            operacion = `'035'`
            estadoMovimiento = `'035001'`
            break;
        case 3:
        default:
            operacion = `'009','017','035'`
            estadoMovimiento = `'009001','017001','035001'`
            break;
    }
    return { operacion, estadoMovimiento }
}