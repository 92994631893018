import ModalManagerContext from 'context/ModalContext'
import { useContext } from 'react'
import { createPortal } from 'react-dom'

const ModalManager = () => {
    const { modals } = useContext(ModalManagerContext)

    return createPortal(
        (modals),
        document.getElementById('root-modal') ?? document.createElement('div')
    )
}

export default ModalManager