import { IConsolidado } from "./interfaces";


export const DATA_MOCK: IConsolidado = {
  "contado": {
    "total": 0,
    "detalle": [
    ]
  },
  "marcas_privadas": {
    "total": 0,
    "detalle": [
    ]
  },
  "otros_medios_pagos": {
    "total": 0,
    "detalle": [

    ]
  },
  "consumo_interno": {
    "total": 0
  },
  "credito": {
    "total": 0,
    "detalle": [

    ]
  },
  "recaudo": {
    "total": 0
  }
}

