import Swal from "sweetalert2"
import success from './successIcon.svg'
import error from './errorWarning.svg'
import upload from './uploadIcon.svg'
import network from './NetworkIcon.svg'
import warning from './warningIcon.svg'
import info from './infoIcon.svg'

export type SwalIcon = 'upload' | 'success' | 'error' | 'network' | 'warning' | 'info'
interface ISwalTerpel {
    title?:string,
    descripcion?:string,
    onClose?: () => void,
    icon:SwalIcon,
    html?:string
}

const swalIcons: { [type in SwalIcon]: React.ReactNode } = {
    error: error,
    network: network,
    success: success,
    upload: upload,
    info:info,
    warning:warning
}

export const SwalTerpel =({title='',descripcion='',icon,onClose = ()=>{},html=''}:ISwalTerpel) =>{
    Swal.fire({
        html:`<img src="${swalIcons[icon]}">
         <h3 style="margin-top:10px; margin-bottom:2px; font-size:18px; font-weight:bold">${title}</h3>
         <div>
         <p>${descripcion}</p>
         ${html}
         </div>
         
         `,
        confirmButtonColor:'#DF1A09',
        customClass: {
          icon: 'no-border'
        }
      }).then(()=>onClose())
}