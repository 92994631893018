import React from "react"
import ModalManagerContext from "context/ModalContext"
import useModalManager from "hooks/ModalManager"

const ModalManagerContextProvider = ({ children }: any) => {
    const modalContextCurrentValue = useModalManager()

    return (
        <ModalManagerContext.Provider value={modalContextCurrentValue}>{children}</ModalManagerContext.Provider>
    )
}

export default ModalManagerContextProvider