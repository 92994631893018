import React, { useEffect } from 'react';
import Highcharts from 'highcharts';

import './ChartPresupuestoPDV.css'

interface Props {
  data: any[],
  type: 'line' | 'area',
  color?: string,
  length: 1 | 2 | 3,
  updateData: number,
}

const ChartPresupuestoPDV = ({ data, type, color, length, updateData }: Props) => {

  const crearGrafica = () => {

    const options = {
      chart: {
        type: type,
        series: {
          color: color,
        }
      },
      title: {
        text: '',
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          format: '${value}',
        },
      },
      xAxis: {
        type: 'Number',
        title: {
          style: { color: 'black', fontSize: '1.2rem' }
        },
      },

      plotOptions: {
        series: {
          pointStart: 1,
          pointInterval: 1,
          marker: {
            enabled: false,
          },
        },

      },
      series: data.map((item) =>
      ({
        name: item.name,
        color: item.color,
        data: item.data,
      })
      ),
    }
    Highcharts.chart("container__chartPDV", options as any as Highcharts.Options)
  }

  useEffect(() => {
    crearGrafica();
  }, [updateData, data]);

  return (
    <div
      id="container__chartPDV"
      className="col-md-11 mx-auto"
    /*style={
        isShow
            ? { marginTop: "15px", display: "block" }
            : { display: "none" }
    }*/
    ></div>
    /* <div className={`container__chart  `}>
       <HighchartsReact
         highcharts={Highcharts}
         options={options}
       />
           </div> */
  )
};

export default ChartPresupuestoPDV;
