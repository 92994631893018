import React, { createContext, useContext, useMemo, useState } from 'react';
import { setearMiles } from "../../../../../util/funciones"
import './NewIndex.css'

const IndicadorContext = createContext({});

// CARD CONTAINER ---------------
export default function Indicadores(props) {

  const { icon, subtitulo, titulo, datoArriba, datoAbajo, descripcionAbajo, color, tipo, imgDefault, bg_color = '', style = '', className = '', children } = props
  const [propsContext, setPropsContext] = useState({
    color,
    icon,
    titulo,
    imgDefault,
    subtitulo,
    datoArriba,
    tipo,
    descripcionAbajo,
    datoAbajo
  })

  const value = useMemo(() => ({ propsContext, setPropsContext }), [propsContext])

  return (
    <IndicadorContext.Provider value={value}>
      <div
        className={`card_indicadores ${className}`}
        style={{ border: `1px solid ${color}`, margin: "5px 0", backgroundColor: bg_color, borderRadius: '10px', ...style }}
      >
        {children}
      </div>
    </IndicadorContext.Provider>

  );
}

// HEADER ------------------------------
export function IndicadorHeader(props) {
  const { icon, titulo, imgDefault, style = {}, className = '', colorTitulo = '#fff' } = props;

  return (
    <div className={`card_indicadores-header ${className}`} style={{ ...style }}>
      <img
        alt={titulo}
        src={icon}
        onError={(e) => { e.target.onerror = null; e.target.src = imgDefault }}
      />
      <h4 className="" style={{ color: colorTitulo }}>{titulo}</h4>
    </div>
  )
}
// BODY ------------------------------
export function IndicadorBody(props) {
  const { subtitulo, datoArriba } = props;
  const { propsContext } = useContext(IndicadorContext)

  return (
    <div className="card_indicadores-body">
      <span>
        {subtitulo}
      </span>
      <p style={{ color: propsContext.color }}>{datoArriba}</p>
    </div>
  );
}
// FIRTS FOOTER ----------------------------
export function IndicadorFooterItem(props) {
  const { tipo, desc, value, style = {}, className = '' } = props;
  const { propsContext } = useContext(IndicadorContext)

  let esValorMonetario = ['$'].includes(`${value}`.slice(0, 1));
  let estilos = !esValorMonetario ? { fontSize: '1.5rem' } : {};

  return (
    <div className={`${className} card_indicadores_items`} style={{ ...style, backgroundColor: propsContext.color }}>
      <h4>{tipo}</h4>
      <span>{desc}</span>
      <p style={estilos}>{value}</p>
    </div>
  );
}

// SECOND FOOTER
export function IndicadorFooter(props) {
  const { style = '', className = '', title, tipo, value } = props;
  const { propsContext } = useContext(IndicadorContext)

  return (
    <div className={`card_indicadores-footer ${className}`} style={{ backgroundColor: propsContext.color, ...style }}>
      <div style={{ ...style }}>
        <p>
          {title}
        </p>
        <span>
          {!tipo && "$"} {value === null ? 0 : setearMiles(value)}
        </span>
      </div>
    </div>
  )
}

// CONTAINER ITEMS
export function ContainerItems(props) {
  const { children } = props;
  return (
    <div className='container_items'>
      {children}
    </div>
  )
}
