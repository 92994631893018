import { RuleObject } from 'antd/es/form'
import jsPDF from 'jspdf'

interface IColumn {
    header:string,
    dataKey:string
}
const textoCentrado = (doc: any, text: string, alto: number): any => {
    const textWidth = (doc.getStringUnitWidth(text) * doc.internal.getFontSize()) / doc.internal.scaleFactor
    const textOffset = (doc.internal.pageSize.width - textWidth) / 2
    return doc.text(textOffset, alto, text)
  }
export const exportTablePDF = (data:any, columnsPDF:IColumn[], nombrePDf:string, tamañoHoja=[850,900], fontSize=9  ): void => {
    const document: any = new jsPDF('l', 'pt', tamañoHoja)
    document.setFontSize(14)
    textoCentrado(document, nombrePDf, 20)
    document.setFont('helvetica', 'italic')
    document.setFontSize(10)
    document.autoTable({
      startY: 50,
      body: data,
      columns: columnsPDF,
      styles: { fontSize: fontSize },
      headStyles: { fillColor: [186, 0, 0] }
    })
    document.save(nombrePDf + '.pdf')
}

const existKeys = (items:any[], labelKey:string,valueKey:string):boolean=>{
  return items.every(item =>{
    const keys = Object.keys(item)
    return keys.includes(labelKey) && keys.includes(valueKey)
  })
}

export const parseToSelectOption = (items:any[], labelKey:string,valueKey:string):{label:string,value:any}[] =>{
    if(existKeys(items,labelKey,valueKey)){
      return items.map((item)=>({label:item[labelKey], value:item[valueKey]}))
    }else{
      throw new Error('keys not exists')
    }
}

export const filterOptions = (input:any, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

export function filtrarJSON(jsonInput: { [key: string]: any }): { [key: string]: any } {
  const filteredJSON: { [key: string]: any } = {};

  for (const key in jsonInput) {
    const value = jsonInput[key];

    if (value !== null && value !== undefined) {
      if (typeof value === 'string' && value.trim() !== '') {
        filteredJSON[key] = value;
      } else if (Array.isArray(value) && value.length > 0) {
        filteredJSON[key] = value;
      } else if (typeof value !== 'string' && !Array.isArray(value)) {
        filteredJSON[key] = value;
      }
    }
  }
  return filteredJSON;
}

export function calcularDigitoVerificacion(nit: number): number {
  // Factores predefinidos para el cálculo (de izquierda a derecha)
  const factores = [3, 7, 13, 17, 19, 23, 29, 37, 41, 43, 47, 53, 59, 67, 71];

  // Convertir el NIT a una lista de enteros
  const nitArray = nit.toString().split('').map(Number);

  // Multiplicar cada dígito del NIT por su factor correspondiente
  // Los factores aplican de derecha a izquierda
  const suma = nitArray.reverse().reduce((acc, current, index) => {
      return acc + current * factores[index];
  }, 0);

  // Calcular el residuo de la suma entre 11
  const residuo = suma % 11;

  // Determinar el dígito de verificación
  if (residuo === 0 || residuo === 1) {
      return residuo;
  } else {
      return 11 - residuo;
  }
}

export function toUpperCaseStrings(obj:any) {
  const newObj:any = {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      newObj[key] = obj[key].toUpperCase().trim();
    } else {
      newObj[key] = obj[key];  // Mantener los valores que no son string sin cambios
    }
  }

  return newObj;
}

export function replaceNullWithUndefined(obj:any) {
  // Recorre todas las claves del objeto
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Si el valor es null, lo reemplaza con undefined
      if (obj[key] === null) {
        obj[key] = undefined;
      } 
      // Si el valor es un objeto, llama la función de manera recursiva
      else if (typeof obj[key] === 'object' && obj[key] !== null) {
        replaceNullWithUndefined(obj[key]);
      }
    }
  }
  return obj;
}

// Ejemplo de uso
const data = {
  name: "Juan",
  age: null,
  address: {
    city: "Barranquilla",
    postalCode: null
  }
};


export const noSpecialCharsRule = (rule: RuleObject, value: string) => {
  // Permite letras, números y espacios, pero excluye caracteres especiales
  if (value && /[^a-zA-Z0-9 ]/g.test(value)) {
    return Promise.reject("No se permiten caracteres especiales, solo letras, números y espacios");
  }
  return Promise.resolve();
};