import React, { useEffect, useState } from 'react'
import { ISelecctionButtons } from '../../ChartHelpers/Charts.interface'
import ActionsButtons from './ActionsButtons'
const FiltrosFecha = ({ noToday, onChange, prevActive }: ISelecctionButtons): React.ReactElement => {
    const [active, setActive] = useState(0)
    useEffect(() => {
        if (prevActive)
            setActive(prevActive)
    })
    const buttons = ['HOY', 'ESTA SEMANA', 'ESTE MES', 'ESTE AÑO']

    const handleOnChange = (date: number) => {
        setActive(date)
        onChange(date)
    }

    return (
        <div className="row mx-auto" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
            {buttons.map((btn, pos) => noToday && pos === 0 ? null : (
                <ActionsButtons text={btn} action={() => handleOnChange(pos)} active={active === pos} key={pos} />
            ))}
        </div>
    )
}
export default FiltrosFecha
