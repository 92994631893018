import React, { useEffect, useRef, useState } from 'react'
import Storage from 'util/store/Storage'
import { Form, Input, Modal, Button, Carousel, Select } from 'antd'
import ModalEditUserInfo from './ModalEditUserInfo'
import { actualizarEmpleado, getCiudadesByDeparamento, getDepartamentoByPais, getPaises, getPersonaById } from 'app/vistas/Usuarios/Empleados/servicios'
import { ILocacion } from 'app/vistas/Usuarios/Empleados/Formulario/DatosContactoEmpleado'
import { RULES_MODAL_PROFILE } from './helper/ModalRules'
import Swal from 'sweetalert2'
import { AxiosError } from 'axios'
import './index.css'

interface Props {
  open: boolean,
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

interface CarouselRef {
  next: () => void;
  prev(): () => void;
  goTo(slide: number, animate: boolean): () => void;
}

const tiposSangre = ['O+', 'B+', 'A+', 'AB-', 'B-', 'O-', 'A-', 'AB+']
const generos = ['MASCULINO', 'FEMENINO', 'OTRO']
const requiredFields = ['nombre', 'apellido', 'correo', 'pais', 'provincia', 'ciudad'];

const ModalUserInfo = ({ open, setOpen }: Props) => {
  const [form] = Form.useForm();
  const { usuario, negocio, dominio } = Storage.getState()
  const [currentUser, setCurrentUser] = useState({} as any)
  const carouselRef = useRef<CarouselRef>(null);
  const [formView, setFormView] = useState(true)
  const [isEditPass, setIsEditPass] = useState(false)
  const [guardandoEmpleado, setGuardandoEmpleado] = useState(false)

  const [locaciones, setLocaciones] = useState({
    paises: [] as ILocacion[],
    departamentos: [] as ILocacion[],
    ciudades: [] as ILocacion[],
  })

  const [cambioPais, setCambioPais] = useState({} as any)
  const [cambioProvincia, setCambioProvincia] = useState({} as any)

  const GetUserById = async () => {
    const { data } = await getPersonaById(usuario.p_id)
    setCurrentUser(data.data)
  }

  const handleEditClick = () => {
    carouselRef.current?.goTo(1, false)
    setIsEditPass(true)
  };

  const editForm = () => {
    setFormView(false)
  }

  const cleanForm = () => {
    getSessionUser()
    setFormView(true)
    for (const field of requiredFields) {
      form.setFields([{ name: field, errors: [] }]);
    }
  }

  const onClose = () => {
    cleanForm()
  }

  const previusTab = () => {
    carouselRef.current?.goTo(0, false)
    setIsEditPass(false)
    setFormView(true)
  }



  const objectEmpleado = (values: any) => {

    const direccion = values?.direccion.trim() == "N/A" ? "" : values?.direccion.trim().toUpperCase()

    const foto = currentUser.atributos.url_foto != "N" ? currentUser.atributos.url_foto : undefined

    return {
      persona: {
        nombres: values?.nombre?.trim().toUpperCase(),
        apellidos: values?.apellido?.trim().toUpperCase(),
        identificacion: values?.identificacion?.trim().toUpperCase(),
        atributos: {
          tipo_identificacion: currentUser?.atributos?.tipo_identificacion,
          contactos: [
            {
              descripcion: "TELEFONO",
              contacto: values?.telefono,
              tipo: 1,
              principal: "S"
            },
            {
              descripcion: "CORREO",
              contacto: values?.correo.trim().toUpperCase(),
              tipo: 2,
              principal: "S"
            },
            {
              descripcion: "DIRECCION",
              contacto: direccion,
              tipo: 3,
              principal: "S"
            }
          ],
          genero: values?.genero,
          tipo_sangre: values?.tipoSangre,
          pais: values?.pais,
          provincia: values?.provincia,
          ciudad: values?.ciudad,
          url_foto: foto ? "S" : "N",
          cliente: "N",
          proveedor: "N",
          propietario: "N"
        },
        empresas_id: currentUser?.empresas_id?.value,
        cargo: [
          currentUser?.perfiles != null ? currentUser?.perfiles[0].value : undefined
        ]
      },
      create_user: usuario.p_id,
      empresas_id: negocio,
      dominio_id: dominio,
      id: currentUser?.id,
      detalles: {
        "NOMBRE": values?.nombre?.trim().toUpperCase(),
        "APELLIDOS": values?.apellido?.trim().toUpperCase(),
        "TIPO DOCUMENTO": currentUser?.atributos?.tipo_identificacion.label,
        "IDENTIFICACION": values?.identificacion?.trim().toUpperCase(),
        "TELEFONO": values?.telefono,
        "CORREO": values?.correo,
        "DIRECCION": direccion,
        "EMPRESA": currentUser?.empresas_id?.label,
        "CARGO": currentUser?.perfiles != null ? currentUser?.perfiles[0].label : undefined,
        "GENERO": values?.genero.value + '',
        "TIPO DE SANGRE": values?.tipoSangre.value + '',
        "PAIS": values?.pais.label,
        "DEPARTAMENTO": values?.provincia?.label,
        "CIUDAD": values?.ciudad?.label,
        "FOTO": foto ? "S" : "N"
      },
      imagen: foto ? "S" : "N"
    }
  }



  const obtenerPaises = async () => {
    const res = await getPaises(negocio)
    setLocaciones({ ...locaciones, paises: res.data.data })
  }

  const obtenerDepartamentos = async () => {
    const paisFieldValue = form.getFieldValue(['pais']);
    const res = await getDepartamentoByPais(paisFieldValue.value, negocio);
    if (res.data.data.length === 0) {
      form.setFieldsValue({ provincia: null });
      form.setFields([{ name: 'provincia', errors: ['No hay departamentos disponibles para este país'] }]);
      setLocaciones({ ...locaciones, departamentos: [] });
      return;
    }

    setLocaciones((prev) => ({ ...prev, departamentos: res.data.data }));
    form.setFields([{ name: 'provincia', errors: [] }]);
  };


  const obtenerCiudades = async () => {
    const ProvinciaFieldValue = form.getFieldValue(['provincia']);
    if (ProvinciaFieldValue == null && locaciones.departamentos.length == 0) {
      form.setFieldsValue({ ciudad: null });
      setLocaciones({ ...locaciones, ciudades: [] });
      form.setFields([{ name: 'ciudad', errors: ['No hay Ciudades disponibles para este Departamento'] }]);
      return
    }

    const res = await getCiudadesByDeparamento(ProvinciaFieldValue?.value, negocio)
    if (res.data.data.length === 0) {
      form.setFieldsValue({ ciudad: null });
      form.setFields([{ name: 'ciudad', errors: ['No hay Ciudades disponibles para este Departamento'] }]);
      setLocaciones({ ...locaciones, ciudades: [] });
      return;
    }

    setLocaciones(prev => ({ ...prev, ciudades: res.data.data }))
    form.setFields([{ name: 'ciudad', errors: [] }]);
  }

  const handleOk = () => {
    cleanForm()
    setOpen(false)
    setIsEditPass(false)
    carouselRef.current?.goTo(0, false)
  }

  const handleFormProfile = (field: any, optionValue: any) => {
    form.setFieldsValue({ [field]: optionValue });
    if (field == 'pais') {
      setCambioPais(optionValue)
      form.setFieldsValue({ provincia: null });
    } else if (field == 'provincia') {
      setCambioProvincia(optionValue)
      form.setFieldsValue({ ciudad: null });
    }
  };

  const getSessionUser = () => {
    form.setFieldValue('identificacion', currentUser?.identificacion || 'N/A')
    form.setFieldValue('nombre', (currentUser?.nombres) || 'N/A')
    form.setFieldValue('apellido', (currentUser?.apellidos) || 'N/A')
    form.setFieldValue('genero', (currentUser?.atributos?.genero) || 'N/A')
    form.setFieldValue('direccion', currentUser?.atributos?.contactos
      .find((contacto: any) => contacto.descripcion == 'DIRECCION')?.contacto || 'N/A')
    form.setFieldValue('telefono', currentUser?.atributos?.contactos
      .find((contacto: any) => contacto.descripcion == 'TELEFONO')?.contacto || 'N/A')
    form.setFieldValue('correo', currentUser?.atributos?.contactos
      .find((contacto: any) => contacto.descripcion == 'CORREO')?.contacto || 'N/A')
    form.setFieldValue('tipoSangre', currentUser?.atributos?.tipo_sangre || 'N/A')
    form.setFieldValue('pais', currentUser?.atributos?.pais || 'N/A')
    form.setFieldValue('provincia', currentUser?.atributos?.provincia || 'N/A')
    form.setFieldValue('ciudad', currentUser?.atributos?.ciudad || 'N/A')
  }

  const onSubmit = async (values: any) => {
    try {
      setGuardandoEmpleado(true)
      const { provincia, ciudad } = values

      for (const field of requiredFields) {
        if (!values[field] || values[field].trim === '') {
          form.setFields([{ name: 'provincia', errors: ['Campo obligatorio'] }]);
          return
        }
      }

      if (!provincia || !provincia?.value) {
        form.setFields([{ name: 'provincia', errors: ['Seleccione un Departamento'] }]);
        return;
      }
      if (!ciudad || !ciudad?.value) {
        form.setFields([{ name: 'ciudad', errors: ['Seleccione un ciudad'] }]);
        return;
      }
      const res = await actualizarEmpleado(objectEmpleado(values))
      handleOk()
      GetUserById()
      Swal.fire({
        icon: 'success',
        title: res.data.mensaje ?? 'Se ha guardado el empleado',
        customClass: {
          container: 'my-swal'
        }
      })
    } catch (error) {
      const err = error as AxiosError
      handleOk()
      if (Array.isArray(err.response?.data.mensaje)) {
        Swal.fire({
          icon: 'error',
          title: err.response?.data.mensaje[0].mensaje || 'Error al guardar empleado',
          customClass: {
            container: 'my-swal'
          }
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: err.response?.data.mensaje || 'Error al guardar empleado',
          customClass: {
            container: 'my-swal'
          }
        })
      }
    } finally {
      setGuardandoEmpleado(false)
    }


  }

  useEffect(() => {
    GetUserById()
  }, [])

  useEffect(() => {
    getSessionUser()
  }, [currentUser])

  useEffect(() => {
    obtenerPaises()
  }, [currentUser])

  useEffect(() => {
    obtenerDepartamentos()
  }, [cambioPais, currentUser])

  useEffect(() => {
    obtenerCiudades()
  }, [cambioPais, cambioProvincia, currentUser, locaciones.departamentos])


  return (
    <Modal
      title="Información del Perfil"
      open={open}
      style={{ zIndex: 1000 }}
      onOk={handleOk}
      closable={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      width={650}
      footer={[
        (!formView && !isEditPass) && (
          <Button style={{ color: 'red' }} onClick={handleEditClick} type="link">
            Editar Contraseña
          </Button>),
        isEditPass && (<Button style={{ color: 'red' }} onClick={previusTab} type="link">
          Cancelar
        </Button>),
        <Button key="guardar" type="primary" onClick={handleOk}>
          Cerrar
        </Button>,
      ]}
    >
      <Carousel
        ref={carouselRef as React.MutableRefObject<any>}
        afterChange={(slide: number) => console.log(slide)}
        dots={false}
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          onFinish={onSubmit}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item label="Identificación" name="identificacion">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Nombre(s)" name="nombre" rules={RULES_MODAL_PROFILE.nombre}>
            <Input disabled={formView} />
          </Form.Item>
          <Form.Item label="Apellido(s)" name="apellido" rules={RULES_MODAL_PROFILE.apellido}>
            <Input disabled={formView} />
          </Form.Item>
          <Form.Item label="Género" name="genero">
            <Select
              disabled={formView}
              placeholder="Seleccionar Género"
              onChange={(_key: any, option: any) => handleFormProfile('genero', option)}
              options={generos.map((genero: any) => ({
                label: genero,
                value: genero
              }))}
            />
          </Form.Item>
          <Form.Item label="Dirección" name="direccion">
            <Input disabled={formView} />
          </Form.Item>
          <Form.Item label="Teléfono" name="telefono" rules={RULES_MODAL_PROFILE.telefono}>
            <Input disabled={formView} />
          </Form.Item>
          <Form.Item label="Correo" name="correo" rules={RULES_MODAL_PROFILE.correo}>
            <Input disabled={formView} />
          </Form.Item>
          <Form.Item label="Tipo de Sangre" name="tipoSangre">
            <Select
              disabled={formView}
              placeholder="Seleccionar un tipo de sangre"
              onChange={(_key: any, option: any) => handleFormProfile('tipoSangre', option)}
              options={tiposSangre.map((tipo: any) => ({
                label: tipo,
                value: tipo
              }))}
            />
          </Form.Item>
          <Form.Item label="País" name="pais">
            <Select
              disabled={formView}
              onChange={(_key: any, option: any) => handleFormProfile('pais', option)}
              options={locaciones.paises}
            />
          </Form.Item>
          <Form.Item label="Departamento" name="provincia">
            <Select
              disabled={formView}
              onChange={(_key: any, option: any) => handleFormProfile('provincia', option)}
              options={locaciones.departamentos}
            />
          </Form.Item>
          <Form.Item label="Ciudad" name="ciudad">
            <Select
              disabled={formView}
              onChange={(_key: any, option: any) => handleFormProfile('ciudad', option)}
              options={locaciones.ciudades}
            />
          </Form.Item>


          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {formView ? (
              <Button onClick={editForm}>Editar datos</Button>
            ) : (
              <>
                <Button onClick={onClose}>Cancelar</Button>
                <Button htmlType="submit" loading={guardandoEmpleado}>Guardar</Button>
              </>
            )}
          </div>
        </Form>

        <ModalEditUserInfo onOpen={handleOk} onClose={formView} />
      </Carousel>
    </Modal >
  );
}

export default ModalUserInfo
