export const DEFAULT_MODAL_MANAGER_CONTEXT: {
    modals: React.ReactElement[],
    actions: {
        addModalToStack: Function,
        removeModalFromStack: Function,
        replaceModal: Function
    }
}
    = {
    modals: [],
    actions: {
        replaceModal: () => { },
        addModalToStack: () => { },
        removeModalFromStack: () => { }
    }
}
