import { format, parse } from 'date-fns'
import dayjs, { UnitType } from 'dayjs'
require('dayjs/locale/es')
dayjs.locale('es')

export const formatosFecha = {
  fecha: 'DD/MM/YYYY',
  completa: 'DD/MM/YYYY hh:mm a',
  inputs: 'YYYY/MM/DD',
  tiempo: 'hh:mm A',
  timezone: 'YYYY-MM-DDTHH:mm:ss',
  completaServidor: 'YYYY-MM-DD HH:mm:ss',
  fechaServidor: 'YYYY-MM-DD',
  horaServidor: 'HH:mm:ss',
  fechaHora: 'DD/MM hh:mm:ss a',
  tiempo2: 'hh:mm:ss A',
}

export const formatosFechasLiterales = {
  fecha: 'DD/MMM/YYYY'
}

export const cambiosFecha = {
  anio: 'year',
  mes: 'month',
  dia: 'days',
  hora: 'hour',
  minuto: 'minute',
  segundo: 'second',
  milisegundo: 'millisecond'
}

export const formatearFecha = (fecha: string | any, formato: string): string => {
  try {
    if (fecha.includes('T') && fecha.includes('.000Z')) {
      fecha = fecha
        .replace(/-/g, '/')
        .replace(/T/, ' ')
        .replace(/.0{3}Z/, '')
    }
  } catch (error) {}

  return dayjs(fecha).format(formato)
}

export const formato12Horas =(format24: string)=> {
  // Parsear la hora en formato HH:mm:ss
  const date = parse(format24, 'HH:mm:ss', new Date());

  // Formatear la hora en formato de 12 horas con AM o PM
  const format12 = format(date, 'hh:mm a');

  return format12;
}

export const setearFecha = (fecha: string | any, aCambiar: string, valor: number): string => {
  return dayjs(fecha)
    .set(aCambiar as UnitType, valor)
    .format()
}

export const modificarDias = (fecha: Date, dias: number): any => {
  return fecha.setDate(fecha.getDate() + dias)
}
export const restarFecha = (fecha: string | any, aCambiar: any, valor: number): string => {
  return dayjs(fecha)
    .subtract(valor, aCambiar)
    .format()
}

export const obtenerPrimerDiaMes = (fecha: string | any): Date => {
  return new Date(fecha.getFullYear(), fecha.getMonth(), 1)
}
export const obtenerUltimoDiaMes = (fecha: string | any): Date => {
  return new Date(fecha.getFullYear(), fecha.getMonth() + 1, 0)
}

export const ObtenerDiaSemana = (dia: string): number => {
  const dias = ['DOMINGO', 'LUNES', 'MARTES', 'MIERCOLES', 'JUEVES', 'VIERNES', 'SABADO']
  return dias.indexOf(dia)
}

export const iterarFechas = (fechaInicio: string | any, fechaFin: string | any): Date[] => {
  const fechasArray = []
  let i = new Date(fechaInicio)
  for (; i <= new Date(fechaFin); i.setDate(i.getDate() + 1)) {
    fechasArray.push(new Date(i))
  }
  return fechasArray
}

export const cantidadDiasEnMes = (diaBuscar: number, rangoDias: Date[]): number => {
  /**
   * Obtener cuantos dias de una semana hay en un mes
   * @param {int} diaBuscar El dia de la semana a buscar ejemplo(martes = 2).
   * @param {Date[]} rangoDias El rango de fechas donde buscaremos el dia
   * @returns {number} La sumatoria de todos los dias encontrados en el rango de fechas.
   */
  return rangoDias.filter(dia => dia.getDay() === diaBuscar).length
}
