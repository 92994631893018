import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { setearMiles } from 'util/funciones'
import { DATA_MOCK } from '../utils/contanst'
import './AcordionDetail.css'
const AcordionDetail = ({ datos = DATA_MOCK, negocio = 'COMBUSTIBLE' }) => {

    
    return (
        <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}
                expandIcon={<ExpandMore />}
            >
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 className='title' style={{ color: '#DE1B08' }} >{negocio}</h1>
                    </Grid>
                    <Grid item xs={12} md={2} >
                        <h3 className='title text-center' >Contado</h3>
                        <h2 className='text-center mount'>{'$' + setearMiles(`${datos.contado.total}`)}</h2>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <h2 className='title' >Marcas privadas</h2>
                        <h2 className='mount'>{'$' + setearMiles(datos.marcas_privadas.total)}</h2>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <h2 className='title'>Otros medios de pago</h2>
                        <h2 className='mount'>{'$' + setearMiles(datos.otros_medios_pagos.total)}</h2>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <h2 className='title' >Consumos internos</h2>
                        <h2 className='mount'>{'$' +setearMiles(datos.consumo_interno.total)}</h2>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <h2 className='title' >Crédito</h2>
                        <h2 className='mount'>{'$' +setearMiles(datos.credito.total)}</h2>
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <h2 className='title' >Recaudos</h2>
                        <h2 className='mount'>{'$' +setearMiles(datos.recaudo.total)}</h2>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails id='table' >
                <table  className="table" >
                    <thead style={{ textAlign: 'center' }}>
                        <tr >
                            <th className="tg-0pky">Contado</th>
                            <th className="tg-0pky">Marcas privadas</th>
                            <th className="tg-0pky">Otros medios de pago</th>
                            <th className="tg-0pky">Consumos propios</th>
                            <th className="tg-0pky">Crédito</th>
                            <th className="tg-0lax">Recaudos</th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: 'center' }}>
                        <tr>
                            <td className="tg-0pky" rowSpan={2}><p>{datos.contado.detalle.map(detalle => <><b className='text-capitalize' style={{ textAlign: 'left' }}>{detalle.medio_pago}: </b>{'$' + setearMiles(detalle.total)}<br></br></>)}</p></td>
                            <td className="tg-0pky" rowSpan={2}><p>{datos.marcas_privadas.detalle.map(detalle => <><b className='text-capitalize' style={{ textAlign: 'left' }}>{detalle.medio_pago}: </b>{'$' + setearMiles(detalle.total)}<br></br></>)}</p></td>
                            <td className="tg-0pky" rowSpan={2}><p>{datos.otros_medios_pagos.detalle.map(detalle => <><b className='text-capitalize' style={{ textAlign: 'left' }}>{detalle.medio_pago}: </b>{'$' + setearMiles(detalle.total)}<br></br></>)}</p></td>
                            <td className="tg-0pky" rowSpan={2}><p>{<><b className='text-capitalize' style={{ textAlign: 'left' }}>{'$' + setearMiles(datos.consumo_interno.total)}</b><br></br></>}</p></td>
                            <td className="tg-0pky" rowSpan={2}><p>{datos.credito.detalle.map(detalle => <><b className='text-capitalize' style={{ textAlign: 'left' }}>{detalle.medio_pago}: </b>{'$' + setearMiles(detalle.total)}<br></br></>)}</p></td>
                            <td className="tg-0lax" rowSpan={2}><p>{<><b className='text-capitalize' style={{ textAlign: 'left' }}>{'$' + setearMiles(datos.recaudo.total)}</b><br></br></>}</p></td>
                        </tr>
                        <tr>
                        </tr>
                        <tr>
                            <td className="tg-0pky"><b>${setearMiles(datos.contado.total)}</b></td>
                            <td className="tg-0pky"><b>${setearMiles(datos.marcas_privadas.total)}</b></td>
                            <td className="tg-0pky"><b>${setearMiles(datos.otros_medios_pagos.total)}</b></td>
                            <td className="tg-0pky"><b>${setearMiles(datos.consumo_interno.total)}</b></td>
                            <td className="tg-0pky"><b>${setearMiles(datos.credito.total)}</b></td>
                            <td className="tg-0lax"><b>${setearMiles(datos.recaudo.total)}</b></td>
                        </tr>
                    </tbody>
                </table>
            </AccordionDetails>
        </Accordion>
    )
}

export default AcordionDetail