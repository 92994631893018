import React from "react"
import { IConsolidado, IDiffReadingTotal } from "./utils/interfaces"
import { objetoVacio } from "util/utils"

export const INITIAL_STATE: INITIAL_STATE = {
    estacion: null,
    departamento: '',
    ciudad: '',
    fecha: new Date()
}

interface INITIAL_STATE {
    estacion: number | null,
    departamento: string,
    ciudad: string,
    fecha: Date
}

export interface IPropsFormConciliador {
    form: INITIAL_STATE,
    setForm: React.Dispatch<React.SetStateAction<INITIAL_STATE>>,
    handlerForm: (name: string, e: any) => void,

    onSumbit: () => void,
    loading: boolean
}

export interface IOptionInfoEDS extends IEstacionInfo {
    label: string
    value: number
}
export interface IEstacionInfo {
    id: string;
    alias: string;
    pais: string;
    ciudad: string;
    departamento: string;
}

export interface Atributos {
    contactos: Contacto[];
    pais: Ciudad;
    provincia: Ciudad;
    ciudad: Ciudad;
    tipo_unidad: string;
    minimo_valor: number;
    centro_logistico: string;
    centro_beneficio: string;
    descriptores: Descriptores;
}

export interface Ciudad {
    value: number;
    label: string;
}

export interface Contacto {
    descripcion: string;
    contacto: string;
    tipo: number;
    principal: string;
}

export interface Descriptores {
    header: string;
    footer: string;
}

export interface Parametros {
}

const FORMAS_PAGO: any = {
    'OTROS MEDIOS DE PAGOS': 'otros_medios_pagos',
    PRIVADA: 'marcas_privadas',
    CONTADO: 'contado',
    RECAUDO: 'recaudo',
    'CONSUMO INTERNO': 'consumo_interno',
    CREDITO: 'credito'
};

const DEDAULT_FORMA_PAGO = {
    total: 0,
    detalle: []
}

export const parseResponseConsolidado = (array: any): IConsolidado => {
    const data: any = {};

    array.forEach((forma: any) => {
        try {
            data[FORMAS_PAGO[forma.forma_pago]] = {
                total: Number(forma.valor_venta),
                detalle: forma.listado_conciliador.map((medio: any) => ({
                    medio_pago: medio.descripcion,
                    total: medio.total_venta,
                })),
            };
        } catch (error) {
            console.log(error);
            data[FORMAS_PAGO[forma.forma_pago]] = DEDAULT_FORMA_PAGO
        }

    });

    return data
};


export function createDateInstance(dateString: string) {
    try {
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day); // Restamos 1 al mes, ya que en el constructor de Date, los meses van de 0 a 11.
        return date;
    } catch (error) {
        return new Date()
    }
}

export const sortFuntion = (array: any[]) => {
    return array.sort((a, b) => {
        const descripcionA = a.descripcion.toUpperCase(); // Convertir a mayúsculas para una comparación sin distinción de mayúsculas y minúsculas
        const descripcionB = b.descripcion.toUpperCase();
        if (descripcionA < descripcionB) {
            return -1;
        }
        if (descripcionA > descripcionB) {
            return 1;
        }
        return 0; // Si son iguales
    });
}

export const DIFF_READING_DEFAULT: IDiffReadingTotal = {
    calibraciones: 0,
    diferencia_lectura: 0,
    diferencia_manguera: 0,
    venta_total: 0
}

export const parseDifferenceReading = (object: any) => {
    try {
        if (objetoVacio(object)) return []
        const diferenciaFinal: any = []
        Object.entries(object).forEach((turno: any) => {
            const productos = Object.entries(turno[1])
            productos.forEach((producto: any) => {
                diferenciaFinal.push({
                    turno: turno[0],
                    producto: producto[0],
                    ...producto[1]
                })
            })
        })
        return diferenciaFinal
    } catch (error) {
        console.log('ERROR', error);
        return []
    }
}