import React, { useEffect, useState } from 'react';
import Storage from 'util/store/Storage';
import { Form, Input, Button } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { actualizarClaveServer, acutalizarPin, getUsuarioByPersonaID } from 'app/vistas/Usuarios/Empleados/servicios';
import { RULES_PASSWORD_EDIT } from './helper/ModalRules';
const md5 = require('md5')

interface Props {
  onOpen: () => any;
  onClose: boolean
}

const requiredFields = ['currentPassword', 'newpassword', 'verifyPassword']



const ModalEditUserInfo = ({ onOpen, onClose }: Props) => {


  const [form] = Form.useForm();
  const { usuario, dominio } = Storage.getState();

  const [actualizandoClave, setActualizandoClave] = useState(false)
  const [actualizandoPin, setActualizandoPin] = useState(false)

  const INITIAL_VALUES = {
    currentPassword: '',
    newpassword: '',
    verifyPassword: '',
    pin: form.getFieldValue(['pin'])
  }

  const togglePasswordVisibility = (field: string) => {
    const fieldKey: any = [field];
    const isPasswordVisible = form.getFieldValue(fieldKey) === 'password';
    form.setFieldsValue({ [fieldKey]: isPasswordVisible ? 'text' : 'password' });
  };


  const handleForm = () => {
    if (onClose) {
      for (const field of requiredFields) {
        form.setFields([{ name: field, errors: [] }]);
      }
    }
    form.resetFields()
  }

  const getInfoPin = async () => {
    const resp = await getUsuarioByPersonaID(usuario.p_id)
    form.setFieldValue('pin', resp[0].pin)
  }

  const handleInputPin = (e: any) => {
    const { value } = e.target

    form.setFieldsValue({ pin: value });
  }

  const onUpdatepin = async () => {

    try {
      setActualizandoPin(true)

      const pinValue = form.getFieldValue(['pin'])

      if (pinValue == '' || !pinValue) {
        form.setFields([{ name: 'pin', errors: ['Este campo no puede ir vacio'] }]);
        return
      }

      const body = {
        identificadorPersona: usuario.p_id,
        pin: pinValue
      }

      await acutalizarPin(body)
      onOpen()
    } catch (error) {
      console.log("🚀 ~ onUpdatepin ~ error:", error)
    } finally {
      setActualizandoPin(false)
    }


  }

  const onSubmit = async (values: any) => {

    try {
      setActualizandoClave(true)
      const { newpassword, verifyPassword } = values

      if (newpassword != verifyPassword) {
        form.setFields([{ name: 'verifyPassword', errors: ['las contraseñas no coinciden'] }]);
        return
      }

      const datos = {
        persona: {
          persona_id: usuario.p_id,
          clave: md5(values?.newpassword),
          clave_Administrador: md5(values?.currentPassword)
        },
        dominio: parseInt(dominio),
        create_user: parseInt(usuario.p_id),
        empresas_id: parseInt(usuario.empresas_id)
      }

      await actualizarClaveServer(datos)
      onOpen()
    } catch (error) {
      console.log("🚀 ~ onSubmit ~ error:", error)
    } finally {
      setActualizandoClave(false)
    }

  }

  useEffect(() => {
    getInfoPin()
  }, [])

  useEffect(() => {
    handleForm()
  }, [onClose])




  return (
    <Form form={form} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} initialValues={INITIAL_VALUES} onFinish={onSubmit}>
      <Form.Item label="Contraseña actual" name="currentPassword" rules={RULES_PASSWORD_EDIT.currentPassword}>
        <Input.Password autoComplete="new-password" />
      </Form.Item>

      <Form.Item label="Nueva contraseña" name="newpassword" rules={RULES_PASSWORD_EDIT.newPassword} >
        <Input.Password
          iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          suffix={
            <Button
              type="link"
              onClick={() => togglePasswordVisibility('newpassword')}
            >
              Mostrar/ocultar
            </Button>
          }
        />
      </Form.Item>
      <Form.Item label="Repite la contraseña" name="verifyPassword" rules={RULES_PASSWORD_EDIT.verifyPassword}>
        <Input.Password
          iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)}
          suffix={
            <Button
              type="link"
              onClick={() => togglePasswordVisibility('verifyPassword')}
            >
              Mostrar/ocultar
            </Button>
          }
        />
      </Form.Item>

      <Form.Item label="Pin" name="pin" wrapperCol={{ span: 16 }}>
        <Input style={{ width: '50%' }} onChange={(value) => handleInputPin(value)} maxLength={4}
          suffix={
            <Button style={{ marginLeft: '8px', background: '#BD412E', color: '#FFFFFF' }}
              onClick={onUpdatepin}
              loading={actualizandoPin}
            >
              Actualizar
            </Button>}
        />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}>
        <Button htmlType="submit" loading={actualizandoClave}>Guardar</Button>
      </Form.Item>
    </Form>
  );
};

export default ModalEditUserInfo;
