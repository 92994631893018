import { createContext } from "react";

export const LastModulesVisitedContext = createContext({} as {
    setLastModules: (name: string, path: string) => void,
    modules: {
        name: string;
        path: string;
    }[]
})

