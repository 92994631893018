import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas
const Clientes = lazy(async () => import('./Clientes'))
// const Terceros = lazy(async () => import('./Terceros'))
const DescClientesPripios = lazy(async () => import('./DescuentoClientesPropios'))
const Flotas = lazy(async () => import('../Empresas/Flotas'))
const Vehiculos = lazy(async () => import('../Empresas/Vehiculos'))
// tsx
const Empleados = lazy(async () => import('./Empleados/index'))
const CuposClientes = lazy(async () => import('./CuposClientes'))

const Usuarios = ({ match }) => {
  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/clientes`} component={Clientes} />
          <Route path={`${match.url}/empleados`} component={Empleados} />
          <Route path={`${match.url}/cupos-cliente`} component={CuposClientes} />
          {/* <Route path={`${match.url}/otros`} component={Terceros} /> */}
          <Route path={`${match.url}/descclientespropios`} component={DescClientesPripios} />
          <Route path={`${match.url}/flotas`} component={Flotas} />
          <Route path={`${match.url}/vehiculos`} component={Vehiculos} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Usuarios
