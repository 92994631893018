export const PAIS = 9
export const DEPARTAMENTO = 10
export const CIUDAD = 11
export const PARAMETROS = 1
export const EMPRESAS = 2
export const PERSONAS = 3
export const VEHICULOS = 4
export const PRODUCTO = 13
export const CATEGORIA = 14
export const FAMILIA = 19
export const FLOTA = 18
export const CLASIFICACION_PRODUCTOS_COMPLEMENTARIOS = 14

export enum TIPOS_PRODUCTOS {
  ID = 19,
  ESTANDAR = 20,
  COMBUSTIBLE = 21,
  SERVICIO = 22
}


export enum TIPOS_SERVICIOS_ESTANDAR {
  NORMALES = 23,
  INGREDIENTES = 24,
  COMPUESTOS = 25,
  PRODUCTOS = 26,
  COMBOS = 32
}
export const arrayProductosEstandar = [TIPOS_SERVICIOS_ESTANDAR.NORMALES, TIPOS_SERVICIOS_ESTANDAR.PRODUCTOS, TIPOS_SERVICIOS_ESTANDAR.INGREDIENTES, TIPOS_SERVICIOS_ESTANDAR.PRODUCTOS, TIPOS_SERVICIOS_ESTANDAR.COMPUESTOS, TIPOS_SERVICIOS_ESTANDAR.COMBOS]

export enum TIPOS_COMBUSTIBLES_ {
  CORRIENTE = 27,
  EXTRA = 28,
  DIESEL = 29,
  GAS = 30,
  GLP = 33,
  ADBLUE = 33
}

export enum TIPOS_SERVICIOS {
  DEFAULT = 47
}
