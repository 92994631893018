import React, { useEffect, useState } from 'react'
//panel del filtro
import Highcharts from 'highcharts'
import HCSoldGauge from 'highcharts/modules/solid-gauge'
import { setearMiles } from 'util/funciones'
import { determinateDate, determinateOperation } from '../ChartHelpers/Chart.helper'
import FiltrosFecha from './Components/FiltrosFecha'
import { getChartsData } from '../ChartHelpers/Charts.services'
import { IDatasetChart } from '../ChartHelpers/Charts.interface'
import { FiltrosOperation } from './Components'
window.Highcharts = Highcharts
const charts = window.Highcharts.charts.filter(item => item !== undefined)

HCSoldGauge(Highcharts)
const ChartTopProductos = ({ updateData, identificadorEstacion }: any): React.ReactElement => {
    const [selectionDate, setSelectionDate] = useState(0)
    const [selectionOperation, setSelectionOperation] = useState(1)

    useEffect(() => {
        getData()
    }, [updateData, selectionDate, selectionOperation, identificadorEstacion, charts])

    const getData = async (): Promise<void> => {
        const { fechaInicial, fechaFinal } = determinateDate(selectionDate)
        const { operacion, estadoMovimiento } = determinateOperation(selectionOperation)
        const body = {
            fecha_inicial: fechaInicial,
            fecha_final: fechaFinal,
            identificadorEstacion: identificadorEstacion,
            tipoMovimiento: operacion,
            tipoEstadoMovimiento: estadoMovimiento
        }
        const data = await getChartsData('PRODUCTOS_MAS_VENDIDOS_HOY', body)
        construirDatos(data)
    }

    const construirDatos = (datos: any[]) => {
        const data: IDatasetChart = {
            categories: [],
            series: []
        }
        try {
            if (datos != null) {
                datos.forEach(promotor => {
                    data.categories.push(`${promotor.descripcion} (${promotor.cantidad} Ventas) (${setearMiles(promotor.cantidad_ventas)} Unidades Vendidas`)
                    data.series.push(parseFloat(promotor.total))
                })
            }
        } catch (error) {}
        construirGrafico(data.categories, data.series)
    }

    const construirGrafico = (categories: string[], series: any[]): void => {
        Highcharts.chart('ventas-top-producto', {
            chart: {
                type: 'bar'
            },
            title: {
                text: ''
            },
            tooltip: {
                formatter(this: Highcharts.TooltipFormatterContextObject) {
                    return '<b>' + this.point.category + '</b>:  $' + setearMiles(this.point.y)
                }
            },
            xAxis: {
                categories: categories
            },
            yAxis: {
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    enabled: false
                }
            },
            legend: {
                reversed: true
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        enabled: true,
                        formatter: function(this: any) {
                            return '$' + setearMiles(this.point.y ?? 0)
                        }
                    },
                    groupPadding: 0.1
                },
                series: {
                    pointWidth: series.length <= 5 ? 25 : 20,
                    color : '#C9961A'
                }
            },
            series: [
                {
                    name: 'ventas',
                    data: series
                }
            ]
        } as any)
    }

    return (
        <>
            <div
                id="ventas-top-producto"
                className="col-md-11 mx-auto"
                /*style={
                isShow
                ? { marginTop: "15px", display: "block" }
                : { display: "none" }
            }*/
            ></div>
            <FiltrosFecha onChange={(selection: number) => setSelectionDate(selection)} />
            {/* <FiltrosOperation
                onChange={(selection: number) => setSelectionOperation(selection)}
                prevActive={selectionOperation}
            /> */}
        </>
    )
}
export default ChartTopProductos

