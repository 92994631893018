import { useState } from "react"

const useModalManager = () => {
    const [modals, setModals] = useState<React.ReactElement[]>([])

    const findModalIndex = (id: string) => modals.findIndex(modal => modal.props.id === id)

    const addModalToStack = (Modal: React.ReactElement) => {
        const index = findModalIndex(Modal.props.id)
        if (index > -1) modals[index] = Modal
        setModals([...modals, Modal])
    }

    const removeModalFromStack = (id: string) => {
        setModals(modals.filter(modal => modal.props.id !== id))
    }



    const replaceModal = (id: string, Modal: React.ReactElement) => {
        const index = findModalIndex(id)
        if (index > -1) {
            modals[index] = Modal
            setModals([...modals])
        }
    }

    return ({
        modals,
        actions: {
            replaceModal,
            addModalToStack,
            removeModalFromStack
        }
    })
}

export default useModalManager
