import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

const CentroDeControl = lazy(async () => import('./centroControl'))
const CentroDeControlMaster = lazy(async () => import('./CentroControlMaster'))
const VersionesPosEds = lazy(async () => import('./versionesPosEds/'))
const Conciliacion = lazy(async () => import('./conciliacion/'))
const GestionVentas = lazy(async () => import('./gestionVentas/'))
const ReportesNotificaciones = lazy(async () => import('./reporteNotificaciones/'))
const ReporteAutorizaciones = lazy(async () => import('./reporte_autorizaciones'))
const TirillaWeb = lazy(async () => import('./tirillaWeb'))
const Soporte = ({ match }: any) => {
  return (
    <div className="app-wrapper">
    <Switch>
    <Suspense fallback={<Loader />}>
      <Route path={`${match.url}/centro_control`} component={CentroDeControl} />
      <Route path={`${match.url}/centro_control_master`} component={CentroDeControlMaster} />
      <Route path={`${match.url}/versiones-pos`} component={VersionesPosEds} />
      <Route path={`${match.url}/conciliacion`} component={Conciliacion} />
      <Route path={`${match.url}/gestion_ventas`} component={GestionVentas} />
      <Route path={`${match.url}/reportenotificaciones`} component={ReportesNotificaciones} />
      <Route path={`${match.url}/reporte_autorizaciones`} component={ReporteAutorizaciones} />
      <Route path={`${match.url}/tirilla_web`} component={TirillaWeb} />
    </Suspense>
  </Switch>
  </div>
  )
}

export default Soporte
