import React, { useEffect, useState } from 'react'
import { App, Button,  Form, Input, Select, Spin} from 'antd'
import { useForm } from 'antd/es/form/Form'
import useLocation from 'hooks/useLocation'
import { createRegionalGroup, createRegionalService, getRegionalByIdService, updateRegionalService } from './FormularioRegionales.services'
import Storage from "util/store/Storage";
import { VALIDATIONS_FORM_REGIONAL } from './FormularioRegionales.helper'
import { filterOptions } from 'util/funcionesNews'
const ID_ESTADOS = {
  ACTIVO: 1,
  INACTIVO: 0
}
const FormularioRegionales = ({ id, refreshData, onClose }: any) => {
  const {notification} = App.useApp()
  const { negocio } = Storage.getState()
  const [loaders, setLoaders] = useState({
    loadingData: false,
    saving: false
  })
  const handlerLoading = (name: string, value: boolean) => setLoaders((prev) => ({ ...prev, [name]: value }))
  const [form] = useForm()
  const [
    countries
  ] = useLocation()
  const onFinish = async (values: any) => {
    try {
      handlerLoading('saving', true)
      if (!id) {
        const responseCreate = await createRegionalService(values)
        const regionalCreated = responseCreate.data.result.id_regional || null
        if (regionalCreated) {
          await createRegionalGroup({
            id_regional: regionalCreated,
            id_negocio_empresa: negocio,
            id_estado: ID_ESTADOS.ACTIVO
          })
          notification.success({ message: 'Regional creada correctamente' })
        }
      } else {
        await updateRegionalService(values, id)
        notification.success({ message: 'Regional editada correctamente' })
      }
      refreshData()
      onClose()
    } catch (error) {
      notification.error({ message: 'Ocurrió un error, intente más tarde' })
    } finally {
      handlerLoading('saving', false)
    }
  }
  useEffect(() => {
    const getInfoRegional = async () => {
      try {
        handlerLoading('loadingData', true)
        const response = await getRegionalByIdService(id)
        form.setFieldValue(['nombre_regional'], response.data.result.nombre_regional)
        form.setFieldValue(['codigo_regional'], response.data.result.codigo_regional)
        form.setFieldValue(['id_pais'], Number(response.data.result.id_pais))
      } catch (error) {
        notification.error({ message: 'Ocurrió un error al obtener los datos, intente más tarde' })

      } finally {
        handlerLoading('loadingData', false)

      }
    }

    if (id) {
      getInfoRegional()
    }
  }, [])

  return (
    <Spin spinning={loaders.loadingData}>
      <Form
        form={form}
        labelCol={{ span: 24 }}
        onFinish={onFinish}
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          name={'nombre_regional'}
          label={'Nombre de la regional'}
          rules={VALIDATIONS_FORM_REGIONAL.nombre_regional}
        >
          <Input size='large' />
        </Form.Item >
        <Form.Item
          name={'codigo_regional'}
          label={'Código de regional'}
          rules={VALIDATIONS_FORM_REGIONAL.codigo_regional}
        >
          <Input size='large' />
        </Form.Item>
        <Form.Item
          name={'id_pais'}
          label={'País'}
          rules={VALIDATIONS_FORM_REGIONAL.id_pais}
        >
          <Select
           options={countries} 
           size='large'
           filterOption={filterOptions}
           showSearch
            />
        </Form.Item>
        <Form.Item >
          <Button loading={loaders.saving} size='large' style={{ width: '100%' }} type='primary' htmlType='submit'>
            Guardar
          </Button>
        </Form.Item>
      </Form>
    </Spin>

  )
}

export default FormularioRegionales