import Loader from 'components/Loader'
import React, { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

const TransaccionesLog = lazy(async () => import('./datafono/TransaccionesLog'))
const Transaccion = ({ match }: any) => {
  console.log('Transaccion', match)
  return (
    <Switch>
    <Suspense fallback={<Loader />}>
      <Route path={`${match.url}/datafono`} component={TransaccionesLog} />
    </Suspense>
  </Switch>
  )
}

export default Transaccion