import React from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { setThemeColor } from 'actions/index';
import Header from "./Header/index";
import Tour from "components/Tour/index";
import { COLLAPSED_DRAWER, FIXED_DRAWER} from "constants/ActionTypes";
import { isIOS, isMobile } from "react-device-detect";
import SideBar from "../../SideBar";
import Storage from 'util/store/Storage'
import {
  TERPEL,
  DARK_INDIGO,
  FUELLY,
  INDIGO
} from 'constants/ThemeColors';
import { DOMINIO_FIREFUEL, DOMINIO_FUELLY, DOMINIO_TERPEL, DOMINIO_ENCOGNV } from '../constantesDominios'
import Footer from "components/Footer";

const Vertical = (props) => {
 
  const { themeColor } = useSelector(({ settings }) => settings);
  const { usuario } = Storage.getState()
  
  try {
    let TIPO_DOMINIO = usuario.dominio.atributos.tipo_dominio
    if (TIPO_DOMINIO === DOMINIO_TERPEL && themeColor !== "terpel") {
      (setThemeColor(TERPEL));
      const body = document.body.classList;
      body.remove(DARK_INDIGO);
      body.remove('dark-theme');
      body.add(TERPEL);
    } else if (TIPO_DOMINIO === DOMINIO_FUELLY && themeColor !== "fuelly") {
      (setThemeColor(FUELLY));
      const body = document.body.classList;
      body.remove(DARK_INDIGO);
      body.remove('dark-theme');
      body.add(FUELLY);
    } else if (TIPO_DOMINIO === DOMINIO_FIREFUEL && themeColor !== "fuelly") {
      (setThemeColor(FUELLY));
      const body = document.body.classList;
      body.remove(DARK_INDIGO);
      body.remove('dark-theme');
      body.add(FUELLY);
    } else if (TIPO_DOMINIO === DOMINIO_ENCOGNV && themeColor !== "indigo") {
      (setThemeColor(DARK_INDIGO));
      const body = document.body.classList;
      body.remove(FUELLY);
      body.remove(INDIGO);
      body.remove('dark-theme');
      body.add(DARK_INDIGO);
      //setMiniDrawer()
    }
  } catch (error) {
    console.log("Error:")
    console.log(error)
  }
  const { drawerType } = useSelector(({ settings }) => settings);
  let drawerStyle = drawerType.includes(FIXED_DRAWER) ? "fixed-drawer" : drawerType.includes(COLLAPSED_DRAWER) ? "collapsible-drawer" : "mini-drawer";
 
  //set default height and overflow for iOS mobile Safari 10+ support.
  if (isIOS && isMobile) {
    document.body.classList.add("ios-mobile-view-height");
  } else if (document.body.classList.contains("ios-mobile-view-height")) {
    document.body.classList.remove("ios-mobile-view-height");
  }
  var color1 = Storage.getState().color1 + " !important"
  return (
    <div className={`app-container ${drawerStyle}`} >
      <Tour />
      <SideBar />

      <div className="app-main-container">
        <div className="app-header" style={{ backgroundColor: color1 }}>
          <Header style={{ backgroundColor: color1 }} />
        </div>

        <main className="app-main-content-wrapper">
          <div className="app-main-content">
            {props.children}
          </div>
         <Footer />
        </main>
      </div>
      {/*<ColorOption />*/}
    </div>
  );
};

export default withRouter(Vertical);
