import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { setearMiles } from 'util/funciones'
import './AcordionDetail.css'
import { IDiffReadingConsolidado } from '../utils/interfaces'
import { Table, Typography } from 'antd'
interface IProps {
    data: IDiffReadingConsolidado
}
export const COLUMNS_DIFERENCES_READING: any = [
    {
        dataIndex: 'turno',
        align: 'center',
        title: 'Descripción del turno'
    },
    {
        dataIndex: 'jornada',
        align: 'center',
        title: 'Turno EDS'
    },
    {
        dataIndex: 'producto',
        align: 'center',
        title: 'Producto'
    },
    {
        title: 'Volumen vendido',
        dataIndex: 'venta_total',
        align: 'center',
    },
    {
        title: 'Calibraciones',
        dataIndex: 'calibraciones',
        align: 'center',
    },
    {
        title: 'Diferencias en manguera',
        dataIndex: 'diferencia_manguera',
        align: 'center',
    },
    {
        title: 'Unidad',
        dataIndex: 'unidad',
        align: 'center',
        render: () => <Typography>GALONES</Typography>
    },
    {
        title: 'Diferencias en lectura',
        dataIndex: 'diferencia_lectura',
        align: 'center',
    },
]

const DiferencesReading = ({ data }: IProps) => {
    return (
        <Accordion>
            <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}
                expandIcon={<ExpandMore />}
            >
                <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 className='title' style={{ color: '#DE1B08' }} >DIFERENCIAS DE LECTURA</h1>
                    </Grid>
                    <Grid item xs={12} md={2} >
                        <h3 className='title text-center mount' >Volumen vendido</h3>
                        <h2 className='text-center mount'>{data.totales.venta_total}</h2>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <h2 className='title mount' >Calibraciones</h2>
                        <h2 className='mount'>{data.totales.calibraciones}</h2>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <h2 className='title mount'>Diferencias en mangueras</h2>
                        <h2 className='mount'>{data.totales.diferencia_manguera}</h2>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <h2 className='title mount' >Diferencias en lectura</h2>
                        <h2 className='mount'>{data.totales.diferencia_lectura}</h2>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails id='table' >
                <Table
                    style={{
                        width: '100%',
                    }}
                    dataSource={data.detalle}
                    columns={COLUMNS_DIFERENCES_READING} 
                    />

            </AccordionDetails>
        </Accordion>
    )
}

export default DiferencesReading