import React, { lazy, Suspense } from 'react';
import { Route, Switch } from "react-router";

import Loader from 'components/Loader';

const PresupuestoPdv = lazy(async () => import('./PresupuestoPdv'));

// ------------------------------------------------------------------------------
export default function Pdv({ match }: any) {
      return (
            <div className="app-wrapper">
                  <Switch>
                        <Suspense fallback={<Loader />}>
                              <Route path={`${match.url}/presupuesto_venta`} component={PresupuestoPdv} />
                        </Suspense>
                  </Switch>
            </div>

      )
}