import Loader from 'components/Loader'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
//rutas


const ParametrizacionAdquirientes = lazy(async ()=> import('./ConfiguracionParametros'))
const ParametrizacionAppRumbo = lazy(async ()=> import('./ParametrizacionAppRumbo'))
const ParametrizacionAppTerpel = lazy(async ()=> import('./ParametrizacionAppTerpel'))
const ParametrizacionClientes = lazy(async ()=> import('./ParametrizacionClientes'))


const Parametrizacion = ({ match }:any) => {

  useEffect(() => {
    console.log(match.url)
  }, [])

  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/parametrizacion_adquirientes`} component={ParametrizacionAdquirientes} />
          <Route path={`${match.url}/app_rumbo`} component={ParametrizacionAppRumbo} />
          <Route path={`${match.url}/app_terpel`} component={ParametrizacionAppTerpel}/>
          <Route path={`${match.url}/parametros`} component={ParametrizacionClientes}/>
        </Suspense>
      </Switch>
    </div>
  )
}

export default Parametrizacion
