import React, { useEffect, useState } from 'react'
import { Widgets, ArrowBackIos } from '@material-ui/icons';
import './MenuFlotante.css'
import Storage from "util/store/Storage"
import { Link } from 'react-router-dom'
import { saveAndGetFavorites } from 'services/Navbar';
import { useSessionStorage } from 'hooks/useSessionStorage';

interface Item {
    descripcion: string,
    link: string,
    identificadorPersona: number,
    _id: string,
    extra: string;
}

const MenuFlotante = () => {

    const { usuario } = Storage.getState()
    const [data, setData] = useState<Item[]>([])
    const [open, setOpen] = useState(false)
    const [hover, setHover] = useState(false);
    const { setSessionStorage } = useSessionStorage()

    useEffect(() => {
        (async () => {
            try {
                const fetchData = {
                    identificadorPersona: usuario.p_id,
                    extra: 'G'
                }
                const result = await saveAndGetFavorites(fetchData)
                setData(result.data)
                setSessionStorage('favoritos', result.data)
            } catch (error) {
                console.log(error)
            }
        })()
    }, [])

    return (
        <>
            {
                data.length > 0 && (
                    <div style={{ right: hover ? '-290px' : '-300px' }} className={`container__menu ${open ? 'open' : ''}`}>
                        <div onClick={() => setOpen(!open)} className={`container__arrow `} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                            <ArrowBackIos />
                        </div>
                        <div className='container__lista'>
                            <ul>
                                {data.map((item) => (
                                    <Link to={item.link} key={item._id}>
                                        <li onClick={() => setOpen(!open)}>
                                            <Widgets />
                                            <span>{item.descripcion.toUpperCase()}</span>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default MenuFlotante
