import Axios from 'axios'
import server from 'util/Server'
import { sessionCaducada } from 'util/funciones'

const headers = {
  Authorization: server.obtenerToken()
}

export const setGlobalAlertsV2 = async (body) => {
  return new Promise(function (resolve, reject) {
    Axios
    .put(server.host + server.puertoCentury + "/api/cupos/getcliente-empresas/alertasGlobales/deshabilitar",
    body,
    { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          // console.log(res.data.data)
          resolve(res.data.data)
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.log(err)
        reject([])
      })
  })
}

export const getGlobalAlertsV2 = async (empresas_id) => {
  return new Promise(function (resolve, reject) {
    Axios.get(server.host + server.puertoCentury + "/api/cupos/getcliente-empresas/alertasGlobales-Obtener/" + empresas_id, { headers: { Authorization: server.obtenerToken() } })
      .then((res) => {
        if (res.status === server.responseOK) {
          // console.log(res.data.data)
          resolve(res.data.data)
        } else if (res.status === server.responseSesionCaducada || res.status === server.responseErrorServidor) {
          sessionCaducada([])
        }
      })
      .catch((err) => {
        console.log(err)
        reject([])
      })
  })
}

export const COLUMNS_TABLE = [
  {
    field: "nombre",
    header: "NOMBRE",
  },
  {
    field: "alerta_mensaje",
    header: "MENSAJE DE ALERTA",
  },
  {
    field: "cupo",
    header: "CUPO",
  },
  {
    field: "estado",
    header: "ESTADO",
  },
  {
    field: "fecha",
    header: "FECHA",
  },
  {
    field: "acciones",
    header: "ACCIONES",
  }
]