import React from 'react'
import DataTableFilterDemo from 'components/Datatable'
import moment from 'moment'
import { Modal, ModalProps } from 'react-bootstrap'
import { formatearFecha } from 'util/formatDate'

const columnasModalAlertaVentasContingencia = [
    {
        field: 'estacion',
        header: 'ESTACION'
    },
    {
        field: 'factura',
        header: 'FACTURA'
    },
    {
        field: 'fecha',
        header: 'FECHA'
    },
    {
        field: 'promotor',
        header: 'PROMOTOR'
    },
    {
        field: 'producto',
        header: 'PRODUCTO'
    },
    {
        field: 'precio',
        header: 'PRECIO'
    },
    {
        field: 'cantidad',
        header: 'CANTIDAD'
    }
]

interface ModalAlertaVentasContingenciaProps extends ModalProps {
    facturas: any[]
}
export default function ModalAlertaVentasContingencia(props: ModalAlertaVentasContingenciaProps) {
    const formatearFacturas = () => {
        const ventas: any[] = []
        props.facturas.forEach(factura => {
            factura.detalles.forEach((detalle: any) => {
                ventas.push({
                    estacion: factura.alias,
                    factura: `${factura.consecutivo_prefijo}${factura.consecutivo}`,
                    fecha: formatearFecha(factura.fecha,'DD/MM/YYYY hh:mm a'),
                    promotor: factura.responsable_nombre,
                    producto: detalle.descripcion,
                    precio: detalle.precio,
                    cantidad: detalle.cantidad
                })
            })
        })
        return ventas
    }

    return (
        <Modal {...props}>
            <Modal.Header placeholder='' closeButton>
                <Modal.Title>VENTAS MANUALES</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DataTableFilterDemo
                    noRefresh
                    data={formatearFacturas()}
                    columns={columnasModalAlertaVentasContingencia}
                />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
        </Modal>
    )
}
