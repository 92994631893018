// @vendors
import React, { useEffect, useState } from 'react'
import moment from 'moment'

// @services
import { getIndicadoresLazoLite } from '../../servicios'
import { getSelectEDS } from 'app/vistas/Reportes/servicios/serviciosSelect'

// @utils
import { capitalizeEachWord } from 'util/capitalizeEachWord'
import Storage from 'util/store/Storage'
import { formatearFecha, formatosFecha } from 'util/formatDate'
import server from 'util/Server'
import { setearMiles } from 'util/utils'

// @constants
import {
    INDICADOR_VENTAS,
    INDICADOR_VEHICULOS,
    // INDICADOR_VENTAS_CANASTILLA,
    INDICADOR_VENTAS_FIDELIZADAS,
} from '../../../modulos_constant'

// @assets
import ventasCanastillaImg from '../../../../../assets/images/widget/mmoney.png'
import ventasCombustiblesImg from '../../../../../assets/images/widget/ventasCombustibles.png'
import LogoHeaderIndicadores from 'assets/images/dashboard/logo_header_indicadores.svg'
import Clock from 'assets/images/clock-regular.svg'


// @components
import Indicador from '../../componentes/indicadores'

// @charts
import { IIndicadoresLazo } from '../../ChartHelpers/Charts.interface'

// @styles
import './index.css'
import { obtenerCierreDia } from 'app/vistas/Empresas/Eds/servicios'
import { esPar } from 'util/funciones'
// import { ShowIndicador } from './ShowIndicador'

const Indicators = ({ vectorIds, indicadores, dominio }: any): React.ReactElement => {


    return (
        <div className="contenedor_master">
            <div className="container-main">
                {/* {vectorIds.includes(INDICADOR_VENTAS_CANASTILLA) && ( */}
                <>
                    {/* <ShowIndicador indicadores={indicadores} ventasCanastillaImg={ventasCanastillaImg} server={server} dominio={dominio} url={server.hostMultimedia} /> */}
                </>
                {/* )} */}
                {vectorIds.includes(INDICADOR_VENTAS_FIDELIZADAS) && indicadores.total_valor_fidelizadas_new && (
                    <Indicador>
                        {/* header */}
                        <Indicador.Header
                            icon={`${server.hostMultimedia}/${dominio}/indicadores/fidelizadas.png`}
                            titulo="VENTAS FIDELIZADAS"
                            imgDefault={ventasCanastillaImg}
                            className={'card_indicadores-ventasFidelizadas'}
                        />
                        {/* FOOTER */}
                        {indicadores.total_valor_fidelizadas_new && (
                            <Indicador.container>
                                {indicadores.total_valor_fidelizadas_new.map((item: any, i: number) => (
                                    <Indicador.Item
                                        tipo={item.descripcion}
                                        desc=""
                                        value={`$ ${setearMiles(item.total_valor_fidelizadas)}`}
                                        className={!esPar(i + 1) ? 'even' : 'odd'}
                                    />
                                ))}
                            </Indicador.container>
                        )}
                    </Indicador>
                )}
                {/* INDICADOR VENTAS TIENDA */}
                {vectorIds.includes(409) && (
                    <>
                        {(+indicadores.total_cantidad_kioscos > 0 || +indicadores.total_valor_kioscos > 0) && (
                            <Indicador>
                                {/* header */}
                                <Indicador.Header
                                    icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasKiosco.png`}
                                    titulo="VENTAS TIENDA"
                                    imgDefault={ventasCanastillaImg}
                                />

                                <Indicador.container>

                                    <Indicador.Item
                                        key={1}
                                        tipo="VENTAS REALIZADAS"
                                        desc=""
                                        value={indicadores.total_cantidad_kioscos ?? 0}
                                        className={'odd'}
                                    />
                                    <Indicador.Item
                                        key={2}
                                        tipo="Total de ventas"
                                        desc=""
                                        value={indicadores.total_valor_kioscos ?? 0}
                                        className={'even'}
                                    />
                                </Indicador.container>
                                {/* Body */}
                                {/* <Indicador.Body
                                subtitulo="VENTAS REALIZADAS"
                                datoArriba={indicadores.total_cantidad_kioscos ?? 0}
                            /> */}
                                {/* FOOTER */}
                                {/* <Indicador.Footer
                                title="Total de ventas"
                                value={indicadores.total_valor_kioscos ?? 0}
                                style={{ textAlign: 'center' }}
                            /> */}
                            </Indicador>
                        )}
                    </>
                )}
                {/* INDICADOR DE VENTA */}
                {vectorIds.includes(INDICADOR_VENTAS) && indicadores.total_valor_combustible_new && (
                    <Indicador >
                        {/* header */}
                        <Indicador.Header
                            icon={`${server.hostMultimedia}/${dominio}/indicadores/ventasCombustibles.png`}
                            titulo="VENTAS COMBUSTIBLE"
                            imgDefault={ventasCombustiblesImg}
                        />
                        {/* FOOTER */}
                        {indicadores.total_valor_combustible_new && (
                            <Indicador.container>
                                {indicadores.total_valor_combustible_new.map((item: any, i: number) => (
                                    <Indicador.Item
                                        key={i}
                                        tipo={item.descripcion}
                                        desc=""
                                        value={`$ ${setearMiles(item.total_valor_combustible)}`}
                                        className={!esPar(i + 1) ? 'even' : 'odd'}
                                    />
                                ))}
                            </Indicador.container>
                        )}
                    </Indicador>
                )}
                {/* INDICADOR VENTAS VEHICULO */}
                {vectorIds.includes(INDICADOR_VEHICULOS) && (
                    <Indicador>
                        {/* header */}
                        <Indicador.Header
                            icon={`${server.hostMultimedia}/${dominio}/indicadores/vehiculos.png`}
                            titulo="VENTAS VEHICULO"
                            imgDefault={ventasCanastillaImg}
                        />
                        {/* ITEMS */}
                        <Indicador.container>
                            <Indicador.Item
                                tipo={"Total Vehículos"}
                                desc=""
                                value={indicadores.total_vehiculos}
                                key={1}
                                className="odd"
                            />
                            <Indicador.Item
                                tipo={"Total de Ventas"}
                                desc=""
                                value={`$ ${setearMiles(indicadores.ventas_vehiculos ?? 0)}`}
                                key={2}
                                className="even"
                            />
                        </Indicador.container>
                        {/* Body */}
                        {/* <Indicador.Body subtitulo="TOTAL VEHICULOS" datoArriba={indicadores.total_vehiculos} /> */}
                        {/* FOOTER */}
                        {/* <Indicador.Footer
                            title="Total de ventas"
                            value={indicadores.ventas_vehiculos ?? 0}
                            style={{ textAlign: 'center' }}
                        /> */}
                    </Indicador>
                )}
            </div>
        </div>
    )
}

const BarraIndicadores = ({ updateData, identificadorEstacion, id, width }: any): React.ReactElement | null => {
    const [indicadores, setIndicadores] = useState({} as IIndicadoresLazo)
    const [vectorIds, setVectorIds] = useState<any[]>([])
    const { usuario, dominio } = Storage.getState()
    const [cierreDia, setcierreDia] = useState({} as any)
    const [area, setArea] = useState({
        lg: 0,
        md: 0,
        sm: 0,
        xs: 0
    })
    const [selectedEds, setSelectedEds] = useState<string>('')
    useEffect(() => {
        indicadoresLazo()
    }, [updateData, identificadorEstacion])
    useEffect(() => {
        obtenerModulos()
    }, [])

    const getTime = async () => {
        const dataTime = await obtenerCierreDia(identificadorEstacion);
        setcierreDia(dataTime.data.data)
    }

    const obtenerData = async (): Promise<void> => {
        try {
            const response = await getSelectEDS(String(identificadorEstacion))
            if (response.length > 1) {
                setSelectedEds('TODAS')
            } else {
                const eds = response[0].alias.toLowerCase()
                setSelectedEds(capitalizeEachWord(eds))
            }
        } catch (error) {
            console.error('TYPE ERROR: ' + error)
        }
    }

    useEffect(() => {
        obtenerData()
        getTime()
    }, [identificadorEstacion])

    const obtenerModulos = () => {
        const modulos = usuario.modulos
        const ids: any[] = []

        if (modulos != null) {
            modulos.forEach((mod: any) => {
                ids.push(mod.id)
            })
            setVectorIds(ids)
        }
    }

    const calcularArea = () => {
        switch (width) {
            case 3:
                setArea({
                    lg: 12,
                    md: 12,
                    sm: 12,
                    xs: 11
                })
                break
            case 2:
                setArea({
                    lg: 8,
                    md: 8,
                    sm: 8,
                    xs: 11
                })
                break
            case 1:
                setArea({
                    lg: 4,
                    md: 4,
                    sm: 4,
                    xs: 11
                })
                break

            default:
                break
        }
    }
    useEffect(() => {
        calcularArea()
    }, [width])

    const indicadoresLazo = async () => {
        const hoy = moment()
        const mañana = hoy.clone().add(1, 'days')
        let { usuario } = Storage.getState()
        const sendFetch = {
            //Hora de toma de datos de 6:00AM de hoy a 6:00AM de mañana
            fecha_inicial: `${formatearFecha(hoy, formatosFecha.fechaServidor)} 06:00:00`,
            fecha_final: `${formatearFecha(mañana, formatosFecha.fechaServidor)} 06:00:00`,
            identificadorEstacion: identificadorEstacion,
            identificadorNegocio: usuario.empresas_id
        }
        const {
            total_cantidad_canastilla,
            total_cantidad_combustible,
            total_cantidad_compras,
            total_cantidad_fidelizadas,
            total_cantidad_mixtas,
            total_clientes,
            total_empleados,
            total_estaciones,
            total_productos_canastilla,
            total_productos_combustible,
            total_valor_canastilla,
            total_valor_combustible,
            total_valor_combustible_new,
            total_valor_compras,
            total_valor_fidelizadas,
            total_valor_fidelizadas_new,
            total_valor_mixtas,
            total_cantidad_kioscos,
            total_valor_kioscos,
            total_vehiculos,
            ventas_vehiculos
        } = await getIndicadoresLazoLite(sendFetch)
        setIndicadores({
            total_cantidad_canastilla,
            total_cantidad_combustible,
            total_cantidad_compras,
            total_cantidad_fidelizadas,
            total_cantidad_mixtas,
            total_clientes,
            total_empleados,
            total_estaciones,
            total_valor_combustible_new,
            total_productos_canastilla,
            total_productos_combustible,
            total_valor_canastilla,
            total_valor_combustible,
            total_valor_compras,
            total_valor_fidelizadas,
            total_valor_fidelizadas_new,
            total_valor_mixtas,
            total_cantidad_kioscos,
            total_valor_kioscos,
            total_vehiculos,
            ventas_vehiculos
        })
    }

    if (
        !(vectorIds.includes(INDICADOR_VENTAS_FIDELIZADAS) && indicadores.total_valor_fidelizadas_new) &&
        !(vectorIds.includes(INDICADOR_VENTAS) && indicadores.total_valor_combustible_new) &&
        !vectorIds.includes(INDICADOR_VEHICULOS)
    ) {
        return null
    }

    return (
        <div
            id={id}
            className={`container_indicadores box-lg-${area.lg} box-md-${area.md} box-sm-${area.sm} box-xs-${area.xs}`}
        >
            <div className="background_indicadores">
                <section className='header_indicadores'>
                    <img src={LogoHeaderIndicadores} width={30} height={30} />
                    <h3>Indicadores</h3>
                </section>
                {selectedEds && <h4>{selectedEds}</h4>}
                <section className='footer_indicadores'>
                    <img src={Clock} width={20} height={20} />
                    <h2 >Ciclo: </h2>
                    {
                        cierreDia ?
                            <h2 > {moment(cierreDia.fecha_inicio, 'hh:mm:ss').format('h:mm a')} - {moment(cierreDia.fecha_cierre, 'hh:mm:ss').format('h:mm a')}</h2>
                            :
                            <h2 >
                                6:00 AM  - 6:00 AM
                            </h2>
                    }
                </section>
            </div>
            <Indicators vectorIds={vectorIds} indicadores={indicadores} dominio={dominio} />

        </div>
    )
}
export default BarraIndicadores

